class adminCostUnit
  constructor: ->
    return []

class costUnitConfig
  constructor: ($stateProvider, $provide) ->
    $stateProvider
    .state 'main.admin.revenueAccount',
      url: '/revenue_account'
      abstract: true
      template: "<div ui-view=''></div>"

    .state 'main.admin.revenueAccount.create',
      url: "/create"
      templateUrl: "admin/revenue_account/revenue_account.tpl.html",
      controller: "adminRevenueAccountCreateController",
      data:
        pageTitle: 'Create a revenue account',
        pageName: 'View revenue account'

    .state 'main.admin.revenueAccount.edit',
      url: "/edit/:revenueAccountId",
      templateUrl: "admin/revenue_account/revenue_account.tpl.html",
      controller: "adminRevenueAccountEditController",
      data:
        pageTitle: 'Edit a revenue account',
        pageName: 'View revenue account'

class adminRevenueAccountCreate
  constructor: ($scope, $state, RevenueAccount, toastr) ->
    $scope.revenueAccount = {
      name: ""
      account_number: ""
    }

    $scope.saveRevenueAccount = ->
      revenueAccountPromise = RevenueAccount.post($scope.revenueAccount)
      revenueAccountPromise.then (response) ->
        $state.go 'main.admin.revenueAccount.edit', {'revenueAccountId': response._id}
      , (response) ->
        $scope.errors = response.data.errors
        toastr.error(response.error)


class adminRevenueAccountEdit
  constructor: ($scope, $state, $stateParams, RevenueAccount, toastr) ->
    $scope.model = []
    $scope.data = {}
    $scope.revenueAccount = {
      name: ""
      account_number: ""
    }

    if $stateParams.revenueAccountId?.length
      revenueAccountPromise = RevenueAccount.one($stateParams.revenueAccountId).get()
      revenueAccountPromise.then (response) ->
        $scope.revenueAccount = response

    $scope.clearRevenueAccount = ->
      $scope.revenueAccount =
        name: ""
        account_number: ""
      toastr.success("Revenue account wiped.", "Wipe successful.")

    $scope.cloneRevenueAccount = ->
      $scope.revenueAccount._id = ''
      $scope.revenueAccount.name = $scope.revenueAccount.name + " - Clone"
      toastr.success("Revenue account " + $scope.revenueAccount.name + " was born.", "Clone successful.")
      $scope.saveRevenueAccount()

    $scope.saveRevenueAccount = ->
      if $scope.revenueAccount._id
        RevenueAccount.one($scope.revenueAccount._id)
        .customPUT($scope.revenueAccount)
        .then (data) ->
          $scope.errors = []
          toastr.success(data.name + " saved.", "Edit successful.")
        , (response) ->
          $scope.errors = response.data.errors
          toastr.error(response.error)
      else
        RevenueAccount.post($scope.revenueAccount)
        .then (data) ->
          $scope.errors = []
          $scope.revenueAccount = data
          toastr.success(data.name + " saved.")
          $state.go 'main.admin.revenueAccount.edit', {'revenueAccountId': data._id}
        , (response) ->
          $scope.errors = response.data.errors
          toastr.error(response.error)

    $scope.deleteRevenueAccount = ->
      RevenueAccount.one($scope.revenueAccount._id)
      .customDELETE()
      .then (data) ->
        $scope.errors = []
        toastr.success(data.name + " was eliminated.", "Fare thee well good old revenue account.")
        $scope.clearRevenueAccount()
      , (response) ->
        $scope.errors = response.data.errors
        toastr.error(response.error)

angular.module('admin.revenueAccount', new adminCostUnit())
.config(['$stateProvider', '$provide', costUnitConfig])
.controller('adminRevenueAccountCreateController', ['$scope', '$state', 'RevenueAccount', 'toastr', adminRevenueAccountCreate])
.controller('adminRevenueAccountEditController', ['$scope', '$state', '$stateParams', 'RevenueAccount', 'toastr', adminRevenueAccountEdit])