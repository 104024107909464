class scInteraction
  constructor: ->
    return [
      'sc.term',
      'restangular'
    ]

class interactionConfig
  constructor: ($stateProvider) ->
    $stateProvider
    .state 'main.interaction',
      abstract: true,
      url: '/interaction'
      template: '<div data-ui-view=""></div>'
      controller: 'interactionController',
      data: {}

    .state 'main.interaction.deal',
      abstract: true
      url: '/deal'
      template: '<div data-ui-view=""></div>'

    .state 'main.interaction.deal.edit',
      url: '/edit/:dealId'
      templateUrl: 'interaction/deal.tpl.html'
      controller: 'interactionDealEditController'
      data:
        pageTitle: 'Deal :: edit'
        pageName: 'Edit deal'

    .state 'main.interaction.mandate',
      abstract: true
      url: '/mandate'
      template: '<div data-ui-view=""></div>'

    .state 'main.interaction.mandate.edit',
      url: '/edit/:mandateId'
      templateUrl: 'interaction/mandate.tpl.html'
      controller: 'interactionMandateEditController'
      data:
        pageTitle: 'Mandate :: edit'
        pageName: 'Edit mandate'

    .state 'main.interaction.invoice',
      url: '/invoices'
      templateUrl: 'interaction/invoice.tpl.html'
      controller: 'interactionInvoiceController'
      data: {
        pageTitle: 'Print :: invoices'
        pageName: 'Print'
      }

    .state 'main.interaction.template',
      abstract: true
      url: '/template'
      template: '<div data-ui-view=""></div>'

    .state 'main.interaction.template.create',
      url: '/create'
      templateUrl: "interaction/template.tpl.html",
      controller: "interactionTemplateCreateController",
      data:
        pageTitle: 'Template :: create',
        pageName: 'Create template'

    .state 'main.interaction.template.edit',
      url: '/edit/:templateId'
      templateUrl: 'interaction/template.tpl.html'
      controller: 'interactionTemplateEditController'
      data:
        pageTitle: 'Template :: edit'
        pageName: 'Edit template'

    .state 'main.interaction.term',
      abstract: true
      url: '/term'
      template: '<div data-ui-view=""></div>'

    .state 'main.interaction.term.create',
      url: '/create'
      templateUrl: "interaction/term.tpl.html",
      controller: "interactionTermCreateController",
      data:
        pageTitle: 'Term :: create',
        pageName: 'Create term'

    .state 'main.interaction.term.edit',
      url: '/edit/:termId'
      templateUrl: 'interaction/term.tpl.html'
      controller: 'interactionTermEditController'
      data:
        pageTitle: 'Term :: edit'
        pageName: 'Edit term'


class interaction
  constructor: ($state, $scope) ->
    $scope.$on '$stateChangeSuccess', (event, toState, toParams, fromState, fromParams) ->
      $scope.pageTitle = toState.data.pageTitle if angular.isDefined(toState.data.pageTitle)
      return


class interactionDealEdit
  constructor: (
    $scope, $rootScope, Deal, Draft, Region, toastr, $state, $stateParams, $resource, $translate, Error, $filter) ->
    $scope.format = "dd.MM.yyyy"

    $scope.assigneesList = []

    $scope.search = {}

    $scope.approve = ->
      $scope.deal.approval =
        user_id: $rootScope.state.user._id
        full_name: $rootScope.state.user.full_name
        approval_date: new Date()

    $scope.dateOptions =
      formatYear: 'yy'
      maxDate: new Date(2020, 5, 22)
      minDate: new Date(1900, 12, 31)
      startingDay: 1

    formatInvoiceDates = (invoices) ->
      for invoice in invoices
        if invoice.invoice_date
          invoice.invoice_date = new Date(invoice.invoice_date)

    loadDeal = (dealId) ->
      dealPromise = Deal.service.one(dealId).get()
      dealPromise.then (data) ->
        $scope.deal = data
        $scope.assigneesList = _.cloneDeep(data.assignees)
        formatInvoiceDates($scope.deal.invoices)
        $scope.daterange = {startDate: data.fixed_start, endDate: data.fixed_end}
      , (response) ->
        toastr.error(response.error)

    if $stateParams.dealId?.length
      loadDeal($stateParams.dealId)

    $scope.model = []

    $scope.topic = {}

    $scope.types =
      partyTypes: Deal.partyTypes
      statusTypes: Deal.statusTypes
      invoiceStatusTypes: Deal.invoiceStatusTypes
      debitStatusTypes: Deal.debitStatusTypes

    $scope.deal = {
      measures: []
      ilias_courses: []
      supervisors: []
      assignees: []
      invoices: []
    }

    $scope.filterAssignees = ->
      search = $scope.search

      searchResult = $filter('filter')($scope.deal.assignees, search)
      $scope.assigneesList = searchResult

    $scope.getHeader = () ->
      return [
        $translate.instant('Number')
        $translate.instant('first_name')
        $translate.instant('last_name')
        $translate.instant('user_name')
      ]


class interactionMandateEdit
  constructor: ($scope, $stateParams, $state, Mandate, toastr) ->
    $scope.model = []

    $scope.format = "dd.MM.yyyy"

    $scope.dateOptions =
      formatYear: 'yy'
      maxDate: new Date(2060, 12, 31)
      minDate: new Date(1970, 1, 1)
      startingDay: 1

    $scope.data = {
      statusTypes: Mandate.statusTypes
      partyTypes: Mandate.partyTypes
    }
    $scope.mandate = {
      parties: []
    }

    # load mandate with given id
    if $stateParams.mandateId?.length
      mandatePromise = Mandate.service.one($stateParams.mandateId).get()
      mandatePromise.then (data) ->
        $scope.mandate = data
        $scope.mandate.valid_from = new Date(data.valid_from)
        $scope.mandate.valid_till = new Date(data.valid_till)
      , (response) ->
        $scope.errors = response.data.errors
        toastr.error(response.error)


class interactionTemplateCreate
  constructor: ($scope, $state, Template, toastr) ->
    $scope.model = []
    $scope.data = {}
    $scope.template = {
      bundles: []
    }

    $scope.saveTemplate = ->
      Template.post($scope.template)
      .then (data) ->
        $scope.template = data
        $state.go 'main.interaction.template.edit', {'templateId': data._id}
        toastr.success(data.name + " saved.", "Save successful.")
      , (response) ->


class interactionTemplateEdit
  constructor: ($scope, $stateParams, $state, Template, toastr) ->
    $scope.model = []
    $scope.data = {}
    $scope.template = {
      bundles: []
    }

    # load template with given id
    if $stateParams.templateId?.length
      templatePromise = Template.one($stateParams.templateId).get()
      templatePromise.then (data) ->
        $scope.template = data
      , (response) ->
        toastr.error(response.error)

    $scope.clearTemplate = ->
      $scope.template = {
        name: ""
        bundles: []
      }
      toastr.success("Template wiped.", "Wipe successful.")

    $scope.cloneTemplate = ->
      $scope.template._id = ''
      $scope.template.name = $scope.template.name + " - Clone"
      toastr.success("Template " + $scope.template.name + " was born.", "Clone successful.")
      $scope.saveTemplate()

    $scope.createBundle = ->
      bundle = {
        name: $scope.data.bundle_name,
        terms: []
      }
      $scope.template.bundles.push(bundle)
      $scope.saveTemplate()

    $scope.addTerm = (bundle, term) ->
      term.term_id = term._id
      bundle.terms.push(term)
      $scope.saveTemplate()

    $scope.saveTemplate = ->
      if $scope.template._id
        Template.one($scope.template._id)
        .customPUT($scope.template)
        .then (data) ->
          $scope.template = data
          toastr.success(data.name + " saved.", "Edit successful.")
        , (response) ->
      else
        Template.post($scope.template)
        .then (data) ->
          $scope.template = data
          $state.go 'main.interaction.template', {'templateId': data._id}
          toastr.success(data.name + " saved.", "Save successful.")
        , (response) ->

    $scope.removeBundle = (bundle) ->
      _.remove($scope.template.bundles, bundle)
      $scope.saveTemplate()

    $scope.removeTerm = (bundleTerms, term) ->
      _.remove(bundleTerms, term)
      $scope.saveTemplate()

    $scope.deleteTemplate = ->
      Template.one($scope.template._id)
      .customDELETE()
      .then (data) ->
        toastr.success(data.name + " was eliminated.", "Good bye my fellow template.")
      , (response) ->


class interactionTermCreate
  constructor: ($scope, $state, Term, toastr) ->
    $scope.model = []
    $scope.data = {}
    $scope.term = {}

    $scope.saveTerm = ->
      $scope.term.tags = _.map($scope.term.tags, 'text')
      Term.post($scope.term)
      .then (data) ->
        $scope.term._id = data._id
        $state.go 'main.interaction.term.edit', {'termId': data._id}
        toastr.success(data.name + " saved.", "Save successful.")
      , (response) ->


class interactionTermEdit
  constructor: ($scope, $stateParams, Term, toastr) ->
    $scope.model = []
    $scope.data = {}
    $scope.term = {}

    # load term with given id
    if $stateParams.termId?.length
      termPromise = Term.one($stateParams.termId).get()
      termPromise.then (data) ->
        $scope.term = data
      , (response) ->
        toastr.error(response.error)

    $scope.clearTerm = ->
      $scope.term = {
        name: ""
      }
      toastr.success("term wiped.", "Wipe successful.")

    $scope.cloneTerm = ->
      $scope.term._id = ''
      $scope.term.name = $scope.term.name + " - Clone"
      toastr.success("Term " + $scope.term.name + " was born.", "Clone successful.")
      $scope.saveTerm()

    $scope.saveTerm = ->
      $scope.term.tags = _.map($scope.term.tags, 'text')
      if $scope.term._id
        Term.one($scope.term._id)
        .customPUT($scope.term)
        .then (data) ->
          $scope.term = data
          toastr.success(data.name + " saved.", "Edit successful.")
        , (response) ->
          $scope.errors = response.data.errors
          toastr.error(response.error)
      else
        Term.post($scope.term)
        .then (data) ->
          $scope.term._id = data._id
          toastr.success(data.name + " saved.", "Save successful.")
        , (response) ->

    $scope.deleteTerm = ->
      Term.one($scope.term._id)
      .customDELETE()
      .then (data) ->
        toastr.success(data.name + " was eliminated.", "Good bye my fellow term.")
        $scope.clearTerm()
      , (response) ->

angular.module('sc.interaction', new scInteraction())
.config(['$stateProvider', interactionConfig])
.controller('interactionController', ['$state', '$scope', interaction])
.controller('interactionDealEditController', ['$scope', '$rootScope', 'Deal', 'Draft', 'Region', 'toastr', '$state', '$stateParams', '$resource', '$translate', 'Error', '$filter', interactionDealEdit])
.controller('interactionMandateEditController', ['$scope', '$stateParams', '$state', 'Mandate', 'toastr', interactionMandateEdit])
.controller('interactionTemplateCreateController', ['$scope', '$state', 'Template', 'toastr', interactionTemplateCreate])
.controller('interactionTemplateEditController', ['$scope', '$stateParams', '$state', 'Template', 'toastr', interactionTemplateEdit])
.controller('interactionTermCreateController', ['$scope', '$state', 'Term', 'toastr', interactionTermCreate])
.controller('interactionTermEditController', ['$scope', '$stateParams', 'Term', 'toastr', interactionTermEdit])