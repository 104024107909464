class searchBox
  constructor: ->
    return {
    restrict: 'E'
    scope:
      model: '=ngModel'
      parameters: '='
      placeholder: '@'
      skills: '='
    replace: true
    templateUrl: 'control/search_box.tpl.html'

    controller: ($scope, $attrs, $element, $timeout, $filter) ->
      $scope.placeholder = $scope.placeholder || 'Search ...'
      $scope.params =
        searchParams: []

      $scope.model =
        skills: []
        tags: []

      $scope.searchQuery = ''
      $scope.setSearchFocus = false

      searchDelay = 0
      changeBuffer = []

      updateModel = (command, key, value) ->
        if searchDelay
          $timeout.cancel searchDelay

        changeBuffer = $filter('filter')(changeBuffer, (change) -> change.key != key)

        changeBuffer.push({
          command: command
          key: key
          value: value
        })

        searchDelay = $timeout ->
          angular.forEach changeBuffer, (change) ->
            if change.command == 'delete'
              _.remove($scope.model.skills, (n) -> return n == change.key)
              _.remove($scope.model.tags, (n) -> return n == change.value)
              delete $scope.model[change.key]
            else
              $scope.model[change.key] = change.value
          changeBuffer.length = 0
        , 100

      $scope.$watch 'model', (newValue, oldValue) ->
        if angular.equals(newValue, oldValue)
          return

        angular.forEach $scope.model, (value, key) ->
          if key == 'query' && $scope.searchQuery != value
            $scope.searchQuery = value

          else
            paramTemplate = $filter('filter')($scope.parameters, (param) -> return param.key == key)[0]
            searchParam = $filter('filter')($scope.params.searchParams, (param) -> return param.key == key)[0]

            if paramTemplate != undefined
              if searchParam == undefined
                $scope.addSearchParam(paramTemplate, value, false)
              else if searchParam.value != value
                searchParam.value = value


        angular.forEach $scope.params.searchParams, (value, key) ->
          if !$scope.model.hasOwnProperty(value, key)
            index = $scope.params.searchParams.map((e) ->
              e.key).indexOf(value.key)
            $scope.removeSearchParam index



      $scope.searchParamValueChanged = (param) ->
        updateModel('change', param.key, param.value)

      $scope.searchQueryChanged = (query) ->
        updateModel('change', 'query', query)

      $scope.enterEditMode = (index) ->
        if index == undefined
          return

        searchParam = $scope.params.searchParams[index]
        searchParam.editMode = true

      $scope.leaveEditMode = (index) ->
        if index == undefined
          return

        searchParam = $scope.params.searchParams[index]
        searchParam.editMode = false

        if !searchParam.value
          $scope.removeSearchParam(index)

      $scope.typeaheadOnSelect = (item, model, label) ->
        $scope.addSearchSkill(item)
        $scope.searchQuery = ''
        updateModel('delete', 'query')

      $scope.isUnsedParameter = (value, index) ->
        return $filter('filter')($scope.params.searchParams, (param) -> return param.key == value.key).length == 0

      $scope.addSearchSkill = (searchParam, value) ->
        if !$scope.isUnsedParameter(searchParam)
          return

        $scope.params.searchParams.push({
          key: searchParam._id
          name: searchParam.name
          value: ''
        })

        if searchParam.type == 'tag'
          $scope.model.tags.push(searchParam.name)

        if searchParam.type == 'skill'
          $scope.model.skills.push(searchParam._id)

        updateModel 'add', searchParam.key, value

      $scope.addSearchParam = (searchParam, value, enterEditModel) ->
        if enterEditModel == undefined
          enterEditModel = true

        if !$scope.isUnsedParameter(searchParam)
          return

        $scope.params.searchParams.push({
          key: searchParam.key
          name: searchParam.name
          placeholder: searchParam.placeholder
          value: value || ''
          editMode: enterEditModel
        })

        updateModel 'add', searchParam.key, value

      $scope.removeSearchParam = (index) ->
        if index == undefined
          return

        searchParam = $scope.params.searchParams[index]
        $scope.params.searchParams.splice(index, 1)

        updateModel('delete', searchParam.key, searchParam.name)

      $scope.removeAll = ->
        $scope.params.searchParams.length = 0
        $scope.searchQuery = ''

        $scope.model = {}

      $scope.editPrevious = (currentIndex) ->
        if currentIndex != undefined
          $scope.leaveEditMode(currentIndex)

        if currentIndex > 0
          $scope.enterEditMode currentIndex - 1

        else if $scope.params.searchParams.length > 0
          $scope.enterEditMode $scope.params.searchParams.length - 1

      $scope.editNext = (currentIndex) ->
        if currentIndex == undefined
          return

        $scope.leaveEditMode(currentIndex)

        if currentIndex < $scope.params.searchParams.length - 1
          $scope.enterEditMode currentIndex + 1
        else
          $scope.setSearchFocus = true

      $scope.keydown = (e, searchParamIndex) ->
        handledKeys = [8, 9, 13, 37, 39]
        if handledKeys.indexOf(e.which) == -1
          return

        cursorPosition = getCurrentCaretPosition(e.target)

        if e.which == 8
          if cursorPosition == 0
            $scope.editPrevious(searchParamIndex)
        else if e.which == 9
          if e.shiftKey
            e.preventDefault()
            $scope.editPrevious(searchParamIndex)
          else
            e.preventDefault()
            $scope.editNext(searchParamIndex)
        else if e.which == 13
          $scope.editNext(searchParamIndex)
        else if e.which == 37
          if cursorPosition == 0
            $scope.editPrevious(searchParamIndex)
        else if e.which == 39
          if cursorPosition == e.target.value.length
            $scope.editNext(searchParamIndex)

      getCurrentCaretPosition = (input) ->
        if !input
          return 0

        if typeof input.selectionStart == 'number'
          return if input.selectionDirection == 'backward' then input.selectionStart else input.selectionEnd
        else if document.selection
          input.focus()
          selection = document.selection.createRange()
          selectionLength = document.selection.createRange().text.length
          selection.moveStart('character', -input.value.length)
          return selection.text.length - selectionLength

        return 0

    }

class setFocus
  constructor: ($timeout, $parse) ->
    return {
    restrict: 'A'

    link: ($scope, $element, $attrs) ->
      model = $parse($attrs.setFocus)
      $scope.$watch model, (value) ->
        if value == true
          $timeout ->
            $element[0].focus()
      $element.bind 'blur', ->
        $scope.$apply model.assign($scope, false)
    }

class autoSizeInput
  constructor: ->
    return {
    restrict: 'A'
    scope:
      model: '=ngModel'

    link: ($scope, $element, $attrs) ->
      container = angular.element('<div style="position: fixed; top: -9999px; left: 0px;"></div>')
      shadow = angular.element('<span style="white-space:pre;"></span>')
      maxWidth = if $element.css('maxWidth') == 'none'
      then $element.parent().innerWidth()
      else $element.css('maxWidth')

      resize = ->
        shadow.text $element.val() or $element.attr('placeholder')
        $element.css 'width', shadow.outerWidth() + 10
        return

      $element.css 'maxWidth', maxWidth
      angular.forEach [
        'fontSize'
        'fontFamily'
        'fontWeight'
        'fontStyle'
        'letterSpacing'
        'textTransform'
        'wordSpacing'
        'textIndent'
        'boxSizing'
        'borderLeftWidth'
        'borderRightWidth'
        'borderLeftStyle'
        'borderRightStyle'
        'paddingLeft'
        'paddingRight'
        'marginLeft'
        'marginRight'
      ], (css) ->
        shadow.css css, $element.css(css)

      angular.element('body').append container.append(shadow)
      resize()
      if $scope.model
        $scope.$watch 'model', ->
          resize()
      else
        $element.on 'keypress keyup keydown focus input propertychange change', ->
          resize()
    }


angular.module('sc.control')
.directive('searchBox', [searchBox])
.directive('setFocus', ['$timeout', '$parse', setFocus])
.directive('autoSizeInput', [autoSizeInput])