class scDirective
  constructor: ->
    return []

class inlineEdit
  constructor: ($timeout) ->
    return {
    restrict: 'E'
    templateUrl: 'directive/inline_edit.tpl.html'
    scope:
      model: '='
      update: '&'
      type: '@'
    controller: ($scope) ->

    }

class inlineEasyDateEdit
  constructor: ($timeout) ->
    return {
    restrict: 'E'
    templateUrl: 'directive/inline_date_edit.tpl.html'
    scope:
      model: '='
      update: '&'
      type: '@'
    controller: ($scope) ->

    }

class itemView
  constructor: ($timeout) ->
    return {
    restrict: 'E'
    templateUrl: 'directive/item_view.tpl.html'
    scope:
      model: '='
      fields: '='
      checkId: '='
      sref: '@'
    link: (scope) ->
      scope.getFields = ->
        if scope.model
          content = for field in scope.fields
            content = scope.model[field] if scope.model[field]?
          return content.join(' ')
    controller: ($scope) ->
# Set state for ui-sref - default main.dashboard
      $scope.getState = ->
        if $scope.sref
          return $scope.sref
        else
          return 'main.dashboard'
    }

class dateInput
  constructor: ($timeout) ->
    return {
    restrict: 'E'
    templateUrl: 'directive/date_input.tpl.html'
    scope:
      model: '='
      action: '&'
      format: '@'

    link: (scope) ->
#scope.model = moment(scope.model, scope.format)
      scope.date = scope.model

      scope.update = ->
        scope.model = moment(scope.date, scope.format)
        scope.model = scope.model.toDate()
        console.log(scope.model)
        scope.action()

    }

class typeValueList
  constructor: ($timeout, Mailto, $filter) ->
    return {
    restrict: 'E'
    replace: true
    transclude: true
    templateUrl: 'directive/type_value_list.tpl.html'
    scope:
      model: '='
      options: '='
      newItem: '='
      update: '&'
      mail: '='
      salutation: '@'
      remove: '='
      filterRemove: '='
      achievement: '='
      updateItem: '='
    controller: ($scope) ->
      $scope.editItem = (item) ->
        oldItem = $scope.model.splice($scope.model.indexOf(item), 1)[0]
        $scope.newItem = oldItem

      $scope.removeItem = (list) ->
        spliced = $scope.model.splice($scope.model.indexOf(list), 1)
        if $scope.remove
          $scope.remove(spliced[0])
        $scope.update()

      $scope.removeFilterItem = (item) ->
        $scope.filterRemove(item)

      $scope.updateListItem = (item) ->
        $scope.updateItem(item)

      $scope.sendItem = (item) ->
        options =
          body: $scope.salutation + ','
        href = Mailto.url(item.mail_address, options)
        return href

      $scope.printCertificate = (achievement) ->
        $scope.$parent.printCertificate(achievement)

      # Update model on drag drop events
      $scope.sortableOptions =
        update: ->
          $scope.update()

      $scope.format = "dd.MM.yyyy"

      $scope.dateOptions =
        formatYear: 'yy'
        maxDate: new Date(2020, 5, 22)
        minDate: new Date(1900,12,31)
        startingDay: 1

    link: (scope, elem, attrs, ctrl, transclude) ->
      scope.scopeName = attrs.scopeName
      transclude scope, (clone) ->
        elem.find('data-form-content').append(clone)
    }

class typeValuePartyList
  constructor: ($timeout, Mailto, $filter) ->
    return {
    restrict: 'E'
    replace: true
    transclude: true
    templateUrl: 'directive/type_value_party_list.tpl.html'
    scope:
      model: '='
      options: '='
      newItem: '='
      update: '&'
      mail: '='
      salutation: '@'
      remove: '='
    controller: ($scope) ->
      $scope.editItem = (item) ->
        oldItem = $scope.model.splice($scope.model.indexOf(item), 1)[0]
        $scope.newItem = oldItem

      $scope.removeItem = (list) ->
        spliced = $scope.model.splice($scope.model.indexOf(list), 1)
        if $scope.remove
          $scope.remove(spliced[0])
        $scope.update()

      $scope.sendItem = (item) ->
        options =
          body: $scope.salutation + ','
        href = Mailto.url(item.mail_address, options)
        return href

      # Update model on drag drop events
      $scope.sortableOptions =
        update: ->
          $scope.update()

      $scope.format = "dd.MM.yyyy"

      $scope.dateOptions =
        formatYear: 'yy'
        maxDate: new Date(2020, 5, 22)
        minDate: new Date(1900,12,31)
        startingDay: 1

    link: (scope, elem, attrs, ctrl, transclude) ->
      scope.scopeName = attrs.scopeName
      transclude scope, (clone) ->
        elem.find('data-form-content').append(clone)
    }

class typeValueInput
  constructor: ($timeout) ->
    return {
    restrict: 'E'
    replace: true
    transclude: true
    templateUrl: 'directive/type_value_input.tpl.html'
    scope:
      model: '='
      newItem: '='
      types: '='
      save: '&'
      search: '='
      type: '@'

    link: (scope, elem, attrs, ctrl, transclude) ->
      scope.$watchCollection 'model', (value) ->
        scope.availableTypes = scope.getAvailableTypes()
        scope.newItem[scope.type] = scope.availableTypes[0]

      scope.searchItem = (searchText) ->
        searchPromise = scope.search(searchText)
        searchPromise

      transclude scope, (clone) ->
        elem.find('data-form-content').append(clone)


    controller: ($scope, $element) ->

      $scope.getAvailableTypes = ->
        usedTypes = []
        for item of $scope.model
          usedTypes.push($scope.model[item][$scope.type])
        availableTypes = []
        for type of $scope.types
          if usedTypes.indexOf($scope.types[type]) == -1
            availableTypes.push($scope.types[type])
        return availableTypes

      if !$scope.newItem
        $scope.newItem = {}

      $scope.availableTypes = $scope.getAvailableTypes()

      $scope.editItem = (data) ->
        $scope.newItem = data

      $scope.addItem = ->
# Convert a phone number to full international format
        if $scope.newItem.number
          $scope.newItem.number = $("#number").intlTelInput("getNumber")
        $scope.model.push($scope.newItem)
        $scope.save()
        $scope.newItem = {}
        $scope.availableTypes = $scope.getAvailableTypes()
        $scope.newItem[$scope.type] = $scope.availableTypes[0]
    }

class typeValueSmallInput
  constructor: ($timeout) ->
    return {
    restrict: 'E'
    replace: true
    transclude: true
    templateUrl: 'directive/type_value_small_input.tpl.html'
    scope:
      model: '='
      newItem: '='
      types: '='
      save: '&'
      search: '='
      type: '@'

    link: (scope, elem, attrs, ctrl, transclude) ->
      scope.$watchCollection 'model', (value) ->
        scope.availableTypes = scope.getAvailableTypes()
        scope.newItem[scope.type] = scope.availableTypes[0]

      scope.searchItem = (searchText) ->
        searchPromise = scope.search(searchText)
        searchPromise

      transclude scope, (clone) ->
        elem.find('data-form-content').append(clone)

    controller: ($scope, $element) ->
      $scope.getAvailableTypes = ->
        usedTypes = []
        for item of $scope.model
          usedTypes.push($scope.model[item][$scope.type])
        availableTypes = []
        for type of $scope.types
          if usedTypes.indexOf($scope.types[type]) == -1
            availableTypes.push($scope.types[type])
        return availableTypes

      if !$scope.newItem
        $scope.newItem = {}

      $scope.availableTypes = $scope.getAvailableTypes()

      $scope.editItem = (data) ->
        $scope.newItem = data

      $scope.addItem = ->
        # Convert a phone number to full international format
        if $scope.newItem.number
          $scope.newItem.number = $("#number").intlTelInput("getNumber")
        $scope.model.push($scope.newItem)
        $scope.save()
        $scope.newItem = {}
        $scope.availableTypes = $scope.getAvailableTypes()
        $scope.newItem[$scope.type] = $scope.availableTypes[0]
    }

class typePartyAddressInput
  constructor: ($timeout) ->
    return {
    restrict: 'E'
    replace: true
    transclude: true
    templateUrl: 'directive/type_party_address.tpl.html'
    scope:
      model: '='
      newItem: '='
      types: '='
      save: '&'
      search: '='

    link: (scope, elem, attrs, ctrl, transclude) ->
      scope.$watchCollection 'model', (value) ->
        scope.availableTypes = scope.getAvailableTypes()
        scope.newItem.party_type = scope.availableTypes[0]

    controller: ($scope, $element, $filter, $translate, toastr) ->

      $scope.data = {
        contactAddresses: []
        recipientAddresses: []
        addresses: []
        partyFilter: [
          {name: "Company", fields: ["name", "number"]}
          {name: "Customer", fields: ["first_name", "last_name", "number"]}
        ]
      }

      $scope.getAvailableTypes = ->
        usedTypes = []
        availableTypes = []
        for item of $scope.model
          usedTypes.push($scope.model[item].party_type)
        for type of $scope.types
          if (usedTypes.indexOf($scope.types[type]) == -1)
            availableTypes.push($scope.types[type])
        return availableTypes

      $scope.availableTypes = $scope.getAvailableTypes()

      $scope.editItem = (data) ->
        $scope.newItem = data

      $scope.changeAddress = (address) ->
        $scope.newItem.address = address
        $scope.newItem.address_type = address.address_type
        $scope.newItem.fullAddress = $filter("fullAddress")(address)

      $scope.addItem = ->
        if($scope.newItem && $scope.newItem.fullAddress)
          $scope.model.push($scope.newItem)
          $scope.save()
          $scope.newItem = {}
          delete $scope.contact
          delete $scope.recipient
          $scope.data.addresses = []
          $scope.availableTypes = $scope.getAvailableTypes()
          $scope.newItem.type = $scope.availableTypes[0]
        else
          toastr.error('Save error')

      $scope.selectContact = (model, filter) ->
        $scope.data.activeFilter = filter
        $scope.data.contactAddresses = model.addresses

        $scope.newItem.contact_id = model._id
        $scope.newItem.contact_type = filter
        $scope.newItem.recipient_id = model._id
        $scope.newItem.recipient_type = filter

      $scope.selectRecipient = (model, filter) ->
        $scope.data.activeFilter = filter
        $scope.data.recipientAddresses = model.addresses
        $scope.newItem.recipient_id = model._id
        $scope.newItem.recipient_type = filter
    }

class trashcan
  constructor: ->
    return {
    restrict: 'E'
    templateUrl: 'directive/trashcan.tpl.html'
    transclude: true
    scope:
      model: '='
      trash: '='
      action: '&'
    controller: ($scope) ->
      $scope.remove = ->
        $scope.model.splice($scope.model.indexOf($scope.trash), 1)
        $scope.action()
    }

class treeView
  constructor: ->
    return {
    restrict: 'E'
    templateUrl: 'directive/tree_view.tpl.html'
    transclude: true
    scope:
      model: '='
      height: '='
      width: '='
      action: '&'
    controller: ($scope) ->
#Resize the tree for margin top - bottom
      tree = d3.layout.tree().size([
        $scope.height - 40
        $scope.width - 200
      ])

      buildTree = ->
        nodes = tree.nodes($scope.model.tree).reverse()
        links = tree.links(nodes)
        nodes.forEach (d) ->
          d.y = d.depth * 180 + 100
        $scope.nodes = nodes
        $scope.links = links
      buildTree()

      $scope.getPos = (item) ->
        'translate(' + item.y + ',' + item.x + ')'


      $scope.$watchCollection 'model', ->
        buildTree()

      diagonal = d3.svg.diagonal().source((d) ->
        {
        'x': d.source.y
        'y': d.source.x
        }
      ).target((d) ->
        {
        'x': d.target.y
        'y': d.target.x
        }
      ).projection((d) ->
        [
          d.y
          d.x
        ]
      )

      $scope.getLink = (item) ->
        if item.parent.x and item.x
          s =
            x: item.parent.y
            y: item.parent.x
          t =
            x: item.y
            y: item.x
          return diagonal(
            {
              source: s
              target: t
            })
        'M 0 0 L 0 0'

      colorChart =
        'course': 'fill: rgb(247, 84, 87);'
        'module': 'fill: rgb(250, 205, 5);'
        'sequence': 'fill: rgb(142, 237, 78);'

      $scope.getStyle = (item) ->
        if item.modules
          colorChart.course
        else if item.sequences
          colorChart.module
        else if item.agendas
          colorChart.sequence
        else
          'fill: rgb(133, 161, 255);'

      $scope.collapse = (d) ->
        if d.children
          d._children = d.children
          d.children = null
        else
          d.children = d._children
          d._children = null
        buildTree()

    }


class smartFloat
  constructor: ($timeout, $filter) ->
    FLOAT_REGEXP_1 = /^\$?\d+.(\d{3})*(\,\d*)$/ #Numbers like: 1.123,56
    FLOAT_REGEXP_2 = /^\$?\d+,(\d{3})*(\.\d*)$/ #Numbers like: 1,123.56
    FLOAT_REGEXP_3 = /^\$?\d+(\.\d*)?$/ #Numbers like: 1123.56
    FLOAT_REGEXP_4 = /^\$?\d+(\,\d*)?$/ #Numbers like: 1123,56

    return {
    require: 'ngModel',
    link: (scope, elem, attrs, ctrl) ->
      ctrl.$parsers.unshift (viewValue) ->
        if FLOAT_REGEXP_1.test(viewValue)
          ctrl.$setValidity('float', true)
          return parseFloat(viewValue.replace('.', '').replace(',', '.'))
        else if FLOAT_REGEXP_2.test(viewValue)
          ctrl.$setValidity('float', true)
          return parseFloat(viewValue.replace('.', ''))
        else if FLOAT_REGEXP_3.test(viewValue)
          ctrl.$setValidity('float', true)
          return parseFloat(viewValue)
        else if FLOAT_REGEXP_4.test(viewValue)
          ctrl.$setValidity('float', true)
          return parseFloat(viewValue.replace(',', '.'))
        else
          ctrl.$setValidity('float', false)
          return undefined
      ctrl.$formatters.unshift (modelValue) ->
        return $filter('number')(parseFloat(modelValue), 2)
    }

# this directive can also use negative values
class smartFloatPlusMinus
  constructor: ($timeout, $filter) ->
    FLOAT_REGEXP_1 = /^[-+]?\$?\d+.(\d{3})*(\,\d*)$/  # Numbers like: 1.123,56
    FLOAT_REGEXP_2 = /^[-+]?\$?\d+,(\d{3})*(\.\d*)$/  # Numbers like: 1,123.56
    FLOAT_REGEXP_3 = /^[-+]?\$?\d+(\.\d*)?$/  # Numbers like: 1123.56
    FLOAT_REGEXP_4 = /^[-+]?\$?\d+(\,\d*)?$/  # Numbers like: 1123,56

    return {
    require: 'ngModel',
    link: (scope, elem, attrs, ctrl) ->
      ctrl.$parsers.unshift (viewValue) ->
        if FLOAT_REGEXP_1.test(viewValue)
          ctrl.$setValidity('float', true)
          return parseFloat(viewValue.replace('.', '').replace(',', '.'))
        else if FLOAT_REGEXP_2.test(viewValue)
          ctrl.$setValidity('float', true)
          return parseFloat(viewValue.replace('.', ''))
        else if FLOAT_REGEXP_3.test(viewValue)
          ctrl.$setValidity('float', true)
          return parseFloat(viewValue)
        else if FLOAT_REGEXP_4.test(viewValue)
          ctrl.$setValidity('float', true)
          return parseFloat(viewValue.replace(',', '.'))
        else
          ctrl.$setValidity('float', false)
          return undefined
      ctrl.$formatters.unshift (modelValue) ->
        return $filter('number')(parseFloat(modelValue), 2)
    }


class ValueInput
  constructor: ($timeout) ->
    return {
    restrict: 'E'
    replace: true
    transclude: true
    templateUrl: 'directive/value_input.tpl.html'
    scope:
      model: '='
      newItem: '='
      mapKeys: '='
      types: '='
      save: '&'
    link: (scope, elem, attrs, ctrl, transclude) ->
      transclude scope, (clone) ->
        elem.find('data-form-content').append(clone)
    controller: ($scope, $element) ->
      $scope.format = "dd.MM.yyyy"
      $scope.dateOptions =
        formatYear: 'yy'
        maxDate: new Date(2020, 5, 22)
        minDate: new Date(1900,12,31)
        startingDay: 1
      if !$scope.newItem
        $scope.newItem = {}

      $scope.editItem = (data) ->
        $scope.newItem = data
      $scope.addItem = ->
        if $scope.newItem.model?
          for map in $scope.mapKeys
            $scope.newItem[map.set] = $scope.newItem.model[map.get] if $scope.newItem.model[map.get]?

        $scope.model.push($scope.newItem)

        if $scope.save()
          $scope.save().then (promise) ->
            if not promise
              if _.dropRight($scope.model)
                $scope.model = _.dropRight($scope.model)
        $scope.newItem = {}

    }

class CommentEdit
  constructor: ($timeout, $rootScope) ->
    return {
    restrict: 'E'
    replace: true
    templateUrl: 'directive/comment_edit.tpl.html'
    scope:
      model: '='
      update: '&'
      save: '='
    controller: ($scope, toastr) ->
      $scope.addComment = ->
        if $scope.commentTitle && $scope.commentBody
          $scope.model.push({
            author: $rootScope.state.user.user_name
            body: $scope.commentBody
            title: $scope.commentTitle
          })
          $scope.update()
          $scope.commentBody = ''
          $scope.commentTitle = ''
        else
          toastr.error $translate.instant('Please fill out comment title and comment body')
    }

class CommentList
  constructor: ($timeout, $rootScope) ->
    return {
    restrict: 'E'
    replace: true
    templateUrl: 'directive/comment_list.tpl.html'
    scope:
      model: '='
      update: '&'
      save: '='
    controller: ($scope) ->
    }

class easyDate
  constructor: ($filter) ->
    return {
    restrict: 'A'
    require: 'ngModel'
    scope:
      format: '@'
    link: (scope, element, attr, ctrl) ->
      ctrl.$parsers.unshift (ngModelValue) ->
        date = moment(ngModelValue, scope.format)
        return date
      ctrl.$formatters.unshift (ngModelValue) ->
        if ngModelValue
          ngModelValue = $filter('date')(ngModelValue)
        return ngModelValue
    }

class passwordInput
  constructor: (Generator) ->
    return {
    restrict: 'E'
    templateUrl: 'directive/password_input.tpl.html'
    scope: true
    controller: ($scope) ->
      $scope.generatePassword = ->
        pass = Generator.generatePassword(10)
        $scope.newItem.password = pass
    }

class percentInput
  constructor: ($filter) ->
    return {
    restrict: 'A'
    require: 'ngModel'
    link: (scope, element, attr, ctrl) ->
      ctrl.$parsers.unshift (ngModelValue) ->
        ngModelValue = ngModelValue / 100
        return ngModelValue
      ctrl.$formatters.unshift (ngModelValue) ->
        if ngModelValue
          ngModelValue = ngModelValue * 100
        return ngModelValue
    }

class utcDatePicker
  constructor: ->
    return {
      restrict: 'A'
      priority: 1
      require: 'ngModel'
      link: (scope, element, attrs, ctrl) ->

        ctrl.$formatters.push (value) ->
          if value
            return moment.utc(value).toDate()
          return value

        ctrl.$parsers.push (value) ->
          m = moment(value)
          value = moment(m).utc().add(m.utcOffset(), "m")
          return moment.utc(value).toDate()
    }


# Example:
# delete-button(data-delete="delete()" data-spinning-time=2000)
class approveButton
  constructor: ($timeout) ->
    return {

      restrict: 'E'
      replace: true
      templateUrl: 'directive/approve_button.tpl.html'
      scope:
        approve: '&'
        spinningTime: '@'

      controller: ($scope) ->
        resetButton = ->
          $scope.buttonText = 'Approve'
          $scope.active = false
        resetButton()

        $scope.start = ->
          if $scope.active
            $scope.approve()
            resetButton()
          else
            $scope.buttonText = 'No shit?'
            $scope.active = true
            $timeout ->
              resetButton()
            , $scope.spinningTime or 4000
    }


class csvFileImport
  constructor: ->
    return {
      restrict: 'E'
      replace: true
      transclude: true
      scope:
        errors: '=?'
        content: '=?'
        result: '=?'
        accept: '=?'
      templateUrl: 'directive/csv_file_import.tpl.html'
      link: (scope, element, attrs) ->
        encoding = 'ISO-8859-1'
        separator = ';'

        element.on 'click', (onClickEvent) ->
          document.getElementById('csvFile').value = null

        element.on 'change', (onChangeEvent) ->
          reader = new FileReader
          scope.filename = onChangeEvent.target.files[0].name

          reader.onload = (onLoadEvent) ->
            scope.$apply ->
              content =
                csv: onLoadEvent.target.result.replace(/\r\n|\r/g, '\n')
              scope.content = content.csv
              scope.errors = false
              scope.result = csvToJSON(content)
              scope.result.filename = scope.filename
              return
            return

          reader.readAsText onChangeEvent.target.files[0], encoding
          return

        csvToJSON = (content) ->
          lines = content.csv.split('\n')
          result = []
          headers = lines.shift()
          headers = headers.split(separator)
          for line in lines
            obj = {}
            currentline = line.split(new RegExp(separator + '(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)'))
            if currentline.length == headers.length
              for header, j in headers
                obj[header] = currentline[j]
              result.push obj
          result
    }


angular.module('sc.directive', new scDirective())
.directive('inlineEdit', ['$timeout', inlineEdit])
.directive('inlineEasyDateEdit', ['$timeout', inlineEasyDateEdit])
.directive('itemView', ['$timeout', itemView])
.directive('dateInput', ['$timeout', dateInput])
.directive('typeValueList', ['$timeout', 'Mailto', '$filter', typeValueList])
.directive('typeValuePartyList', ['$timeout', 'Mailto', '$filter', typeValuePartyList])
.directive('typeValueInput', ['$timeout', typeValueInput])
.directive('typeValueSmallInput', ['$timeout', typeValueSmallInput])
.directive('typePartyAddressInput', ['$timeout', typePartyAddressInput])
.directive('trashcan', [trashcan])
.directive('treeView', [treeView])
.directive('smartFloat', ['$timeout', '$filter', smartFloat])
.directive('smartFloatPlusMinus', ['$timeout', '$filter', smartFloatPlusMinus])
.directive('valueInput', ['$timeout', ValueInput])
.directive('commentEdit', ['$timeout', '$rootScope', CommentEdit])
.directive('commentList', ['$timeout', '$rootScope', CommentList])
.directive('easyDate', ['$filter', easyDate])
.directive('passwordInput', ['Generator', passwordInput])
.directive('percentInput', ['$filter', percentInput])
.directive('utcDatePicker', [utcDatePicker])
.directive('approveButton', ['$timeout', approveButton])
.directive('csvFileImport', [csvFileImport])