class scTerm
  constructor: ->
    return []

class term
  constructor: ->
    return {
    restrict: 'E'
    scope:
      ngModel: "="
      updateModel: "&"
    template: '<div></div>'

    link: (scope, elem, attrs) ->
      scope.render(elem, scope.ngModel.template)

    controller: class
      constructor: ($scope, $compile) ->
        $scope.render = (elem, template) ->
          elem.html($compile(template)($scope))

        @setValue = (key, value) ->
          $scope.ngModel.term_args[key] = value

        @getValue = (key) ->
          if key of $scope.ngModel.term_args
            return $scope.ngModel.term_args[key]

        @updateModel = ->
          $scope.updateModel()
    }

class termVarInput
  constructor: ->
    return {
    restrict: 'E'
    replace: true
    require: "^term"
    scope:
      key: "@"

    link: (scope, elem, attrs, termCtrl) ->
      scope.data = termCtrl.getValue(scope.key)

      scope.$watch "data", (value) ->
        if value?
          termCtrl.setValue(scope.key, value)

      scope.updateModel = ->
        termCtrl.updateModel()

    templateUrl: 'directive/term_input.tpl.html'
    controller: ($scope) ->
      $scope.data = null

    }


angular.module('sc.term', new scTerm())
.directive('term', [term])
.directive('termVarInput', [termVarInput])