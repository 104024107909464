class skillPointType
  constructor: ->
    return []


class pointTypeConfig
  constructor: ($stateProvider, $provide) ->
    $stateProvider
    .state 'main.skill.pointType',
      url: '/point_type'
      abstract: true
      template: "<div ui-view=''></div>"

    .state 'main.skill.pointType.create',
      url: "/create"
      templateUrl: "skill/point_type/point_type.tpl.html",
      controller: "skillPointTypeCreateController",
      data:
        pageTitle: 'Create a point type',
        pageName: 'View point type'

    .state 'main.skill.pointType.edit',
      url: "/edit/:pointTypeId",
      templateUrl: "skill/point_type/point_type.tpl.html",
      controller: "skillPointTypeEditController",
      data:
        pageTitle: 'Edit a point type',
        pageName: 'View point type'


class skillPointTypeCreate
  constructor: ($scope, $state, PointType, toastr) ->
    $scope.pointType = {
      name: ""
      exchange_rates: []
    }

    $scope.savePointType = ->
      pointTypePromise = PointType.post($scope.pointType)
      pointTypePromise.then (response) ->
        toastr.success($scope.pointType.name + " was born.", "Create successful.")
        $state.go 'main.skill.pointType.edit', {'pointTypeId': response._id}
      , (response) ->
        $scope.errors = response.data.errors
        toastr.error(response.error)


class skillPointTypeEdit
  constructor: ($scope, $state, $stateParams, PointType, toastr) ->
    $scope.model = []
    $scope.data = {}
    $scope.pointType = {
      name: ""
      exchange_rates: []
    }

    if $stateParams.pointTypeId?.length
      pointTypePromise = PointType.one($stateParams.pointTypeId).get()
      pointTypePromise.then (response) ->
        $scope.pointType = response

angular.module('skill.pointType', new skillPointType())
.config(['$stateProvider', '$provide', pointTypeConfig])
.controller('skillPointTypeCreateController', ['$scope', '$state', 'PointType', 'toastr', skillPointTypeCreate])
.controller('skillPointTypeEditController', ['$scope', '$state', '$stateParams', 'PointType', 'toastr', skillPointTypeEdit])