class scAdmin
  constructor: ->
    return ['admin.revenueAccount', 'admin.costUnit', 'admin.highScore']

class adminConfig
  constructor: ($stateProvider, $provide) ->

    $stateProvider
    .state 'main.admin',
      abstract: true,
      template: "<div ui-view=''></div>"
      url: '/admin'
      controller: 'adminController',
      data: {}


class admin
  constructor: ($state, $scope) ->
    $scope.$on '$stateChangeSuccess', (event, toState, toParams, fromState, fromParams) ->
      $scope.pageTitle = toState.data.pageTitle if angular.isDefined(toState.data.pageTitle)
      return


angular.module('sc.admin', new scAdmin())
.config(['$stateProvider', '$provide', adminConfig])
.controller('adminController', ['$state', '$scope', admin])