class adminCostUnit
  constructor: ->
    return []

class costUnitConfig
  constructor: ($stateProvider, $provide) ->
    $stateProvider
    .state 'main.allocation.zeroTaxCertificate',
      url: "/zero_tax_certificate",
      templateUrl: "allocation/zero_tax/zero_tax_certificate.tpl.html",
      controller: "allocationZeroTaxCertificateController",
      data:
        pageTitle: 'Show zero tax certificates',
        pageName: 'View certificates'


class allocationZeroTaxCertificate
  constructor: ($state, $scope, Booking, $translate, toastr, $resource) ->
    $scope.bookings = []

    $scope.data =
      purchaser_id: null
      purchaser_type: null
      contactFilter: [
        {name: "Company", fields: ["name"]}
        {name: "Customer", fields: ["first_name", "last_name"]}
      ]

    $scope.dateRangePicker =
      dateRange:
        startDate: moment().subtract(0, 'years').startOf('year')
        endDate: moment().subtract(0, 'years').endOf('year')
      options:
        locale:
          format: 'DD.MM.YYYY'

    $scope.selectPurchaser = (model, filter) ->
      $scope.data.purchaser_id = model._id
      $scope.data.purchaser_type = filter

    $scope.search = ->
      payload =
        start_date: $scope.dateRangePicker.dateRange.startDate
        end_date: $scope.dateRangePicker.dateRange.endDate
        purchaser_id: $scope.data.purchaser_id
        purchaser_type: $scope.data.purchaser_type

      toastr.info("Please be patient. This may take a while.", "Searching...")
      Booking.certificateService.post(payload)
      .then (data) ->
        $scope.certificates = data.certificates
        $scope.errors = []
        toastr.success("Bookings found", "Search successful.")
      , (response) ->
        toastr.error(response.error)
        $scope.errors = response.data.errors

    certificate = $resource('download_certificate/', {},
      pdf:
        method: 'POST'
        header:
          accept: 'application/pdf'
        responseType: 'arraybuffer'
        cache: false
        transformResponse: (data, header) ->
          decoder = new TextDecoder()
          msg = decoder.decode(data)
          pdf = undefined
          if data
            pdf = new Blob([data], {type: 'application/pdf'})
          return {
            response: pdf
            text: msg
          }
    )

    $scope.downloadCertificate = (contractor_id, contractor_type, contractor_name) ->
      payload = {
        start_date:  $scope.dateRangePicker.dateRange.startDate
        end_date:  $scope.dateRangePicker.dateRange.endDate
        purchaser_id: $scope.data.purchaser_id
        purchaser_type: $scope.data.purchaser_type
        contractor_id: contractor_id
        contractor_type: contractor_type
      }
      certificate.pdf(payload).$promise
      .then (response) ->
        blob = response.response
        saveAs(blob, contractor_name + '.pdf')
      , (error) ->
        $translate("ErrorMsg").then (text) ->
          toastr.error error.data.text


angular.module('allocation.zeroTax', new adminCostUnit())
.config(['$stateProvider', '$provide', costUnitConfig])

angular.module('sc.allocation')
.controller('allocationZeroTaxCertificateController', ['$state', '$scope', 'Booking', '$translate', 'toastr', '$resource', allocationZeroTaxCertificate])