class translationConfig
  constructor: ($translateProvider) ->
    $translateProvider.translations('gp_de', {
      Loading: "Puderzuckerbestäubung wird gestartet ..."
      PageTitle: "Herzlich willkommen zu Skill Control."
      using: 'verwendet'
      UserName: "Benutzername"
      User: "Benutzer"
      Users: "Benutzer"
      Rank: "Rechtestufe"
      civilian: "Stufe 0"
      private: "Stufe 1"
      corporal: "Stufe 2"
      lieutenant: "Stufe 3"
      general: "Stufe 4"
      master: "Meister"
      god: "Gott"
      department: "Team"
      production: "Akademiemanagement"
      sales: "Vertrieb"
      service: "Kundenberatung"
      operations: "Assistenz der Geschäftsleitung"
      accounting: "Rechnungswesen"
      command: "Geschäftsleitung"
      marketing: "Marketing"
      development: "Entwicklung"
      false: "Nein"
      true: "Ja"
      Customer: 'Kontakt'
      Customers: 'Kontakte'
      Company: 'Unternehmen'
      Companies: 'Unternehmen'
      Contact: "Ansprechpartner"
      Assignee: "Begünstigter"
      Assignees: "Begünstigte"
      assignee: "Begünstigter"
      Attendee: "Teilnehmer"
      Attendees: "Teilnehmer"
      registered: "Firmensitz"
      office: "Büro"
      billing: "Rechnung"
      shipping: "Versand"
      birthplace: "Geburtsort"
      home: "Privat"
      personal: "Privat"
      business: "Büro"
      fax: "Fax"
      Fax: "Fax"
      mobile: "Funk"
      Alert: "Obacht"
      Number: "Kundennummer"
      "Sales zone": "Vertriebsgebiet"
      North: "Nord"
      South: "Süd"
      Unclear: "Unbekannt"
      "Ad stop": "Werbesperre"
      "Adstop": "Werbesperre"
      Source: "Kontakt über"
      Achievement: "Qualifikation"
      Achievements: "Qualifikationen"
      requested: "angestrebt"
      refused: "verweigert"
      started: "begonnen"
      active: "aktiv"
      achieved: "erreicht"
      expired: "abgelaufen"
      aborted: "abgebrochen"
      Tag: "Stichwort"
      Tags: "Stichwörter"
      'Add a Tag': "Stichwort hinzufügen"
      Industry: "Branche"
      Lesson: "Einheit"
      Lessons: "Einheiten"
      Duration: "Dauer"
      Requirement: "Voraussetzung"
      Requirements: "Voraussetzungen"
      Trainer: "Dozent"
      Trainers: "Dozenten"
      Point: "Punkt"
      Amount: "Nettobetrag"
      Point_Type: "Punktesystem"
      Point_Scale: "Skalierung"
      Lifespan: "Lebensdauer"
      Content: "Inhalt"
      Objective: "Lernziel"
      Competence: "Kompetenzart"
      Category: "Lerninhaltsklassifizierung"
      Domain: "Anwendungsgebiete"
      Media: "Lehrmethode"
      Documents: "Dokumente"
      Agenda: "Agenda"
      Agendas: "Agenden"
      Sequence: "Sequenz"
      Sequences: "Sequenzen"
      Module: "Modul"
      Modules: "Module"
      Course: "Kurs"
      Courses: "Kurse"
      Draft: "Vorlage"
      "Draft name": "Name der Vorlage"
      Drafts: "Vorlagen"
      Supervisor: "Verantwortlicher"
      Supervisors: "Verantwortliche"
      Region: "Region"
      Regions: "Regionen"
      Measure: "Maßnahme"
      Measures: "Maßnahmen"
      "Add subscription": "Abonnement hinzufügen"
      "Measure type": "Maßnahmentyp"
      "Launch notification": "Startbenachrichtigungsmail"
      "Measure name": "Maßnahmenname"
      "Clone from draft": "Vorlage kopieren"
      "Fees": "Gebühren"
      "Use layout": "Layout-Vorlage"
      public: "Offen"
      "in-house": "Inhouse"
      incomplete: "Gesperrt"
      available: "Verfügbar"
      complete: "Durchführung"
      retracted: "Abgesagt"
      "Date range": "Zeitraum"
      fixed_start: "Offizieller Start"
      fixed_end: "Offizielles Ende"
      "Measure tree": "Maßnahmenübersicht"
      "Measure fixed start within": "Offizieller Start innerhalb"
      Gig: "Termin"
      Gigs: "Termine"
      Booking: "Auftrag"
      Bookings: "Aufträge"
      Interaction: "Korrespondenz"
      Deal: "Vertrag"
      Deals: "Verträge"
      Letter: "Brief"
      Letters: "Briefe"
      Term: "Klausel"
      Terms: "Klauseln"
      "Merge deals": "Vertragsfusion"
      "Search source deal": "Quellvertrag suchen"
      "Search target deal": "Zielvertrag suchen"
      "Source deals": "Quellverträge"
      "Target deal": "Zielvertrag"
      "Let's go": "Auf geht's"
      Bundle: "Abschnitt"
      Bundles: "Abschnitte"
      Template: "Entwurf"
      Templates: "Entwürfe"
      Virgin: "Unberührt"
      virgin: "Unberührt"
      Initiated: "Begonnen"
      initiated: "Begonnen"
      Submitted: "Vorgeschlagen"
      submitted: "Vorgeschlagen"
      Offered: "Angeboten"
      offered: "Angeboten"
      Rejected: "Abgelehnt"
      rejected: "Abgelehnt"
      Signed: "Unterzeichnet"
      earmarked: "Vorgemerkt"
      Earmarked: "Vorgemerkt"
      signed: "Unterzeichnet"
      Canceled: "Gekündigt"
      canceled: "Gekündigt"
      Nullified: "Aufgehoben"
      nullified: "Aufgehoben"
      Obsolete: "Veraltet"
      obsolete: "Veraltet"
      Fulfilled: "Erfüllt"
      fulfilled: "Erfüllt"
      Seller: "Verkäufer"
      Buyer: "Käufer"
      'Assign assignees': 'Teilnahmen reservieren'
      'Warrant assignees': 'Teilnahmen buchen'
      'Warrant members': 'Kursteilnahmen buchen'
      'Bill fees': 'Gebühren abrechnen'
      'Start quests': "Qualifikation auf 'begonnen'"
      'Suspend quests': "Qualifikation auf 'angestrebt'"
      'Amend quests': "Qualifikation ergänzen"
      'Service Recipient': "Leistungsempfänger"
      'Deal Recipient': "Vertragsempfänger"
      'Invoice Recipient': "Rechnungsempfänger"
      'Invoice Issuer': "Rechnungsaussteller"
      'Invoice comment title': 'Rechnungskommentar Titel'
      'Invoice comment body': 'Rechnungskommentar Text'
      'Invoice comment': 'Rechnungskommentar'
      'provisional': "vorläufig"
      'invoiced': "fakturiert"
      'debited': "sollgestellt"
      'Cash discount': "Skontoprozentsatz"
      'Cash discount days': "Skontofälligkeitstage"
      Debit: "Position"
      Debits: "Positionen"
      Task: "Aufgabe"
      Tasks: "Aufgaben"
      Create: "Neuanlage"
      Import: "Import"
      Merge: "Verschmelzung"
      Clone: "Kopieren"
      Save: "Fertig"
      Wipe: "Neu"
      Delete: "Löschen"
      Document: "Dokument"
      Alerts: "Terminübersicht"
      Charts: "Diagramme"
      Reports: "Berichte"
      Add: "Hinzufügen"
      Previous: "Zurück"
      Next: "Weiter"
      Stage: "Baukasten"
      Allocation: "Konkretes"
      Statistics: "Statistik"
      'Your tasks': "Deine Aufgaben"
      'Upcoming gigs': "Nächste Termine"
      Dashboard: "Übersicht"
      Collection: "Zuordnung"
      Count: "Anzahl"
      Sex: "Geschlecht"
      male: "Junge"
      female: "Mädchen"
      undefined: "Unbekannt"
      "Personal Data": "Persönliches"
      Age: "Alter"
      Meet: "Begrüßung"
      Greet: "Anrede Brief/E-Mail"
      Send: "Beschriftung Briefumschlag"
      Email: "E-Mail"
      Address: "Anschrift"
      Phone: "Telefon"
      Title: "Titel"
      "First Name": "Vorname"
      "Last Name": "Nachname"
      Birthday: "Geburtsdatum"
      Addresses: "Anschriften"
      Other: "Sonstige Angaben"
      Comment: "Kommentar"
      Comments: "Kommentare"
      "Please fill out comment title and comment body": "Bitte geben Sie einen Kommentar und einen Titel an"
      "E-Mail addresses": "E-Mail Adressen"
      "Phone numbers": "Telefonnummern"
      "Related companies": "Zugehörige Unternehmen"
      Street: "Straße"
      "City Code": "Postleitzahl"
      "City Name": "Ort"
      "Country": "Land"
      "c/o": "bei"
      "New comment": "Neuer Kommentar"
      "Some more data": "Zusätzliches"
      first_name: "Vorname"
      last_name: "Nachname"
      Credentials: "Zugangsdaten"
      "User name": "Benutzername"
      "User": "Benutzer"
      Password: "Kennwort"
      "Edit addresses": "Adressen bearbeiten"
      #Comments
      Author: "Autor"
      "All comments": "Alle Kommentare"
      "Edit Location": "Räumlichkeit bearbeiten"
      "Last comment": "Letzter Kommentar"
      "Historic comments": "Was bisher geschah"
      #Customer
      "Available": "Verfügbarkeit"
      "Available customer": "Verfügbare Kontakte"
      "Collisions": "Kollisionen"
      "Selected skills": "Ausgewählte Skills"
      "New Customer": "Neuer Kontakt"
      "Edit Customer": "Kontakt bearbeiten"
      "Edit names": "Persönliches bearbeiten"
      "Edit other": "Sonstige Angaben bearbeiten"
      "Edit busy": "Verfügbarkeitsübersicht"
      "End": "Ende"
      "All day": "Ganztägig"
      "Show schedule": "Stundenplan"
      "Active": "Aktiviert"
      "Inactive": "Deaktiviert"
      "Availability": "Verfügbarkeit"
      "Default address": "Standardanschrift"
      "Send credentials": "Sende Zugangsdaten für"
      "Please check the credential input field values!": "Bitte prüfen Sie die Eingabewerte für die Zugangsdaten!"
      "Add imported contacts as assignees to a deal": "Importierte Kontakte als Begünstigte einem Vertrag hinzufügen"
      "Key value pairs": "Schlüssel-Wert-Paare"
      "Key": "Schlüssel"
      "Key value": "Wert"
      "Key Value": "Wert"
      "Key value search": "Schlüssel-Wert-Suche"
      "Search completed.": "Die Suche ist abgeschlossen."
      "Points certificate": "Punktezertifikat"
      "Selected point types": "Ausgewählte Punktetypen"
      "Print": "Drucken"
      #Company
      "New Company": "Neues Unternehmen"
      "Edit Company": "Unternehmen bearbeiten"
      "Edit related": "Beziehungen bearbeiten"
      "Related supervisors": "Interne Ansprechpartner"
      "Related contacts": "Externe Ansprechpartner"
      "Related": "Beziehungen"
      "Company Name": "Unternehmensname"
      "Topic": "Thema"
      "Search for customers": "Suche nach Kontakten"
      "Search for contacts": "Suche nach Kontakten oder Unternehmen"
      "Search for users": "Suche nach Benutzern"
      "Search locations": "Suche nach Räumlichkeiten"
      "Search for gigs": "Suche nach Terminen"
      "Search for courses": "Suche nach Kursen"
      "Search for achievements": "Suche nach Qualifikationen"
      "Search for measures": "Suche nach Maßnahmen"
      "Search for deals": "Suche nach Verträgen"
      "Search for supervisors": "Suche nach Verantwortlichen"
      #Location
      "New Location": "Neue Räumlichkeit"
      "Location": "Räumlichkeit"
      "Locations": "Räumlichkeiten"
      "Rating": "Bewertung"
      "Capacity": "Kapazität"
      "Price": "Preis"
      "entrance": "Eingang"
      "parking": "Parkplatz"
      #Panel Header
      "Edit": "Bearbeite {{name}}"
      #Measure
      "Related achievements": "Qualifikationsziele"
      "Create measure": "Neue Maßnahme"
      "Add supervisor": "Interne Ansprechpartner hinzufügen"
      "Add contacts": "Externe Ansprechpartner hinzufügen"
      'Deal assignees': "Vertragsbegünstigte"
      "Value": "Nettoeinzelbetrag"
      "Currency": "Währung"
      "Cost unit": "Kostenträger"
      "Cost units": "Kostenträger"
      "Deferrable": "In Raten zahlbar"
      "Discountable": "Rabattierbar"
      "Revenue account": "Erlöskonto"
      "Revenue accounts": "Erlöskonten"
      'projected': 'In Planung'
      'approved': 'Freigegeben'
      'launched': 'Gestartet'
      'suspended': 'Unterbrochen'
      'rejected': 'Abgesagt'
      'completed': 'Beendet'
      'Search layout': "Layout suchen"
      'Measure description': "Maßnahmenbeschreibung"
      'Measure structure': "Maßnahmenstruktur"
      'Add course': "Kurs hinzufügen"
      'Delete course': "Kurs löschen"
      'Add module': "Modul hinzufügen"
      'Delete module': "Modul löschen"
      'Delete sequence': "Sequenz löschen"
      'Gig slot': "Termin-Platzhalter"
      'Wipe gig slot': "Termin-Platzhalter leeren"
      'Delete gig slot': "Termin-Platzhalter löschen"
      'Create gig': "Termin erstellen"
      'Search gig': "Termin suchen"
      'This measure takes place in region': "Diese Maßnahme findet in folgender Region statt:"
      "Add fee": "Gebühr hinzufügen"
      #Deal
      "Create deal": "Neuer Vertrag"
      "Deal number": "Vertragsnummer"
      "Invoice number": "Rechnungsnummer"
      "Debit number": "Buchungssatznummer"
      "Debit date": "Buchungsdatum"
      "Deal status": "Vertragsstatus"
      "Add measure": "Maßnahme hinzufügen"
      "Add assignee": "Begünstigten hinzufügen"
      "Party type": "Rolle im Vertrag"
      "Party contact": "Ansprechpartner"
      "Alternative recipient": "Abweichende Anschrift von"
      "Contracting parties": "Vertragsparteien"
      "Select address": "Anschrift"
      "Select recipient address": "Abweichende Empfängeranschrift"
      "Select template": "Entwurf auswählen"
      "Terms of the deal": "Vertragsbedingungen"
      "Invoices": "Rechnungen"
      "Add invoice": "Rechnung hinzufügen"
      "empty": "Ohne Angabe"
      "seller": "Verkäufer"
      "buyer": "Käufer"
      "middleman": "Vermittler"
      "deal_recipient": "Vertragsempfänger"
      "service_recipient": "Leistungsempfänger"
      "invoice_recipient": "Rechnungsempfänger"
      "Invoice recipient": "Rechnungsempfänger"
      "invoice_issuer": "Rechnungssteller"
      "Invoice issuer": "Rechnungssteller"
      "declaration_recipient": "Erlaubnisinhaber"
      "Quantity": "Anzahl"
      "Debit status": "Zustand des Buchungssatzes"
      "Add debit": "Neue Buchungssatz"
      "Description": "Beschreibung"
      "Invoice date": "Rechnungsdatum"
      "Due date": "Fälligkeitsdatum"
      "debit status": "Buchungssatzstatus"
      "Paid date": "Zahlungsdatum"
      "Invoice number": "Rechnungsnummer"
      "Invoice status": "Rechnungsstatus"
      "Print contract": "Vertrag"
      "Print cover letter": "Anschreiben"
      "Print points declarations": "IDD-Bescheinigungen"
      "Print achievement certificates": "Zertifikate"
      "Download assignees": "Vertragsbegünstigte herunterladen"
      "Approve": "Absegnen"
      "No shit?": "Wirklich?"
      "Notification settings": "Benachrichtigungseinstellungen"
      "Declarations": "Bescheinigungen",
      "Certificates": "Zertifikate"
      #Mandate
      "Mandate": "SEPA-Mandat"
      "Mandates": "SEPA-Mandate"
      "Create mandate": "Neues SEPA-Mandat"
      "Mandate number": "Mandatsreferenznummer"
      "Mandate parties": "Mandatsbeteiligte"
      "Bank details": "Bankdaten"
      "The creation of the document failed.": "Es konnte kein Dokument erstellt werden."
      debitor: "Zahlungspflichtiger"
      depositor: "Kontoinhaber"
      creditor: "Zahlungsempfänger"
      #Grant
      "Grant": "Förderung"
      "Grants": "Förderungen"
      "Create grant": "Neue Förderung"
      "Grant number": "Förderungsnummer"
      "Grant parties": "Förderungsbeteiligte"
      donor: "Förderer"
      grantee: "Förderungssempfänger"
      #Bookings
      "Create Booking": "Neuer Auftrag"
      "Booking number": "Auftragsnummer"
      "Booking type": "Auftragsstyp"
      "Related gigs": "Zugehörige Termine"
      "Net price": "Nettopreis"
      "hotel": "Hotel"
      "trainer": "Dozent"
      "flight": "Flug"
      "car": "Mietwagen"
      "train": "Bahn"
      "location": "Räumlichkeit"
      "author": "Autorentätigkeit"
      "accommodation": "Übernachtung"
      "reimbursement": "Reisekostenübernahme"
      "contractor": "Auftragnehmer"
      "Contractor": "Auftragnehmer"
      "purchaser": "Auftraggeber"
      "Purchaser": "Auftraggeber"
      "Tax free": "Umsatzsteuerbefreit"
      "Booking parties": "Auftragsbeteiligte"
      "Gig trainer": "Dozent eintragen"
      "Degig trainer": "Dozent austragen"
      "Costs": "Kosten"
      "Add cost": "Kosten hinzufügen"
      "Entry date": "Eintrittsdatum"
      "entry date": "Eintrittsdatum"
      "entry_date": "Eintrittsdatum"
      # Zero tax certificates
      "Zero tax certificate": "Umsatzsteuerbefreiungsbescheinigungen"
      #Draft
      "Draft Name": "Name der Vorlage"
      "Create draft": "Neue Vorlage"
      #Course
      "Course name": "Name des Kurses"
      "Create course": "Neuer Kurs"
      "Add sequence": "Sequenz hinzufügen"
      #Sequence
      "Sequence Name": "Name der Sequenz"
      "Create sequence": "Neue Sequenz"
      "Add a module": "Modul hinzufügen"
      #Modul
      "Module name": "Name des Moduls"
      "Create module": "Neues Modul"
      "Add agenda": "Agenda hinzufügen"
      #Agenda
      "Agenda name": "Name der Agenda"
      "Create agenda": "Neue Agenda"
      "Add a lesson": "Einheit hinzufügen"
      #Lesson
      "Lesson content": "Name der Einheit"
      "Add skill": "Skill hinzufügen"
      "Required achievements": "Erforderliche Voraussetzungen"
      "Required for": "Erforderlich für"
      "Lifespan in years": "Lebensdauer in Jahren"
      "Number of points": "Punktzahl"
      "Type of point": "Punkteart"
      "Document name": "Dokumentenname"
      "Document addressee": "Verwender"
      "Distribution": "Verteilung per"
      "Duplex": "Doppelseitig"
      "Hidden": "Verborgener Text"
      "Staple": "Heften"
      "Punch": "Lochen"
      "Format": "Seitenformat"
      "Points": "Punkte"
      "points": "Punkte"
      "Scale": "Größenordnung"
      "Add point": "Punkt hinzufügen"
      #Achievement
      "New achievement": "Neue Qualifikation"
      "Skill of the achievement": "Skill der Qualifikation"
      "Achievement status": "Qualifikationsstatus"
      "Achieved date": "Qualifikationszustandsdatum"
      "General properties": "Allgemeine Eigenschaften"
      "Available from": "Verfügbarkeitsbeginn"
      "Available till": "Verfügbarkeitsende"
      "Renewal properties": "Erneuerungseigenschaften"
      "Period quantity": "Periodenzahl"
      "Renewal period": "Erneuerungsperiodenart"
      "Latecomer mode": "Nachzüglermodus"
      "merciless": "gnadenlos"
      "Skill requirements": "Skillvoraussetzungen"
      "Point requirements": "Punktvoraussetzungen"
      "Point minimum": "Mindestpunktzahl"
      "Duration quantity": "Dauer (Anzahl)"
      "Duration period": "Dauer (Typ)"
      "Terms of the certificate" : "Zertifikatsklauselgebunde"
      "Skill certificate name": "Bezeichnung im Zertifikat"
      "Achievement certificate name": "Bezeichnung im Zertifikat"
      "Select skills": "Bitte einen Skill auswählen"
      "Search skills": "Skills suchen"
      "Search for skills": "Skills suchen"
      "Related skills": "Zugehörige Skills"
      #Error Messages
      "source is missing": "'Kontakt über' ist nicht angegeben."
      "Number is not a phone number": "{{ name }} ist keine gültige Telefonnummer."
      "Invalid email address": "{{ name }} ist keine gültige E-Mail Adresse."
      "capacity is missing": "Kapazität ist nicht angegeben."
      "name is missing": "Der Name ist nicht angegeben."
      "No trainer": "Kein Dozent"
      "MinimumLength": "Es muss mindestens ein {{ field }} gewählt werden."
      # Inline edit directive
      'New course': 'Neuer Kurs'
      'New sequence': 'Neue Sequenz'
      'New module': 'Neues Modul'
      #Toastr Messages
      "Search": "Suche nach {{ type }}"
      "SearchSuccess": "{{ length }} {{ type }} gefunden"
      "SearchWarning": "Bei der Suche wurde nichts gefunden. <i class='icon-no icon-black icon-24' id='icon-no'></i>"
      "CreateMsg": "{{ name }} wurde erfolgreich erstellt."
      "EditMsg": "{{ name }} erfolgreich bearbeitet."
      "ErrorMsg": '''<i class='icon-why icon-black icon-72' id='icon-why'></i><br>
                    Ein unerwartetes Verhalten ist aufgetreten, dass Sie bitte sehr gerne der IT melden möchten.'''
      #Gig
      "gigInfo": '''{{ name.gig.name }} mit Beginn am {{ name.gig.begin }} und
                    Ende um {{ name.gig.end }} für die Agenda {{ name.name }}'''
      "assigned": "reserviert"
      "warranted": "gebucht"
      "allowed": "umgebucht"
      "cat-state": "unbekannt"
      "absent": "abwesend"
      "present": "anwesend"
      "Begin": "Beginn"
      "Import gig": "Terminimport"
      "ImportGigStart": "Terminimport wird gestartet."
      "ImportGigSuccess": "{{ length }} Termine wurden angelegt."
      #Control
      "Measure overview": "Maßnahmenübersicht"
      "Show measures": "Maßnahmen anzeigen"
      "Show gigs": "Termine anzeigen"
      "Gig overview": "Liste der Termine"
      "Gig Name": "Name"
      "Clone from Agenda": "Kopiervorlage Agenda"
      "Extended search": "Erweiterte Suche"
      "Control middleman": 'Vermittlerkontrolle'
      "Middleman": 'Vermittler'
      "Gig date range": 'Terminzeitraum'
      "Debited": 'Sollgestellt'
      "Provisional": 'Vorläufig'
      "Attendances": 'Teilnahmen'
      "Summary": 'Zusammenfassung'
      "Control booking": 'Auftragskontrolle'
      "Control debit": "Buchungssatzkontrolle"
      "Control invoice": "Rechnungskontrolle"
      "Control deal": "Vertragskontrolle"
      "Buyer type": "Käufertyp"
      "Search bookings": "Suche nach Buchungen"
      "Signed deals missing invoices.": "Unterzeichnete Verträge ohne Rechnungen"
      "Signed deals missing parties.": "Unterzeichnete Verträge ohne Vertragsparteien"
      "Signed deals with unselected bundles.": "Unvollständige Klauselauswahl in unterzeichneten Verträgen"
      "Concluded deals missing debits.": "Zustandegekommene Verträge ohne Buchungssätze"
      "Prepared deals waiting for reaction.": "Abwartende, vorbereitete Verträge"
      "Stopped deals with provisional debits.": "Vorzeitig beendete Verträge mit vorläufigen Buchungssätzen"
      "Control hopes": "Hoffnungskontrolle"
      "Turnover": "Umsätze"
      "Workload": "Auslastung"
      "Export type": "Export-Typ"
      "ExportSuccess": "Der HMD Export war erfolgreich, die generierte Datei wird heruntergeladen."
      "ExportStart": "Der HMD Export wurde gestartet, bitte haben Sie einen Moment Geduld."
      "ExportError": "Bei der Generierung der Export Datei ist ein Fehler aufgetreten."
      "QuerySuccess": "Die Abfrage war erfolgreich."
      "QueryStart": "Die Abfrage wurde gestartet, bitte haben Sie einen Moment Geduld."
      "QueryError": "Bei der Abfrage ist ein Problem aufgetreten."
      "Search for achievements and tags": "Suche nach Qualifikationen und Stichwörtern."
      "Freebie bookings.": "Aufträge ohne Kosten"
      "Pending bookings.": "Aufträge in der Schwebe"
      "Bookings without gigs.": "Aufträge ohne Termine"
      "Deflowered bookings without parties.": "Entjungferte Aufträge ohne Auftragsbeteiligte"
      "Control gigs": "Terminkontrolle"
      "Gigs without begin.": 'Termine ohne Beginn'
      "Neverending gigs.": "Endlose Termine"
      "Neither trainer nor attendees.": "Termine ohne Dozenten oder Teilnehmer"
      "End is before begin.": "Terminende vor dem Terminbeginn"
      "Gigs with begin equal end.": "Terminbeginn gleich Terminende"
      "Overbooked future gigs.": "Überbuchte Termine in der Zukunft"
      "Crammed future gigs.": "Gerammelt volle Termine in der Zukunft"
      "Gig name": "Terminname"
      "Control LMS": "LMS-Kontrolle"
      "Control company": "Unternehmenskontrolle"
      "Selected ILIAS courses": "Ausgewählte ILIAS Kurse"
      "ILIAS course members without ILIAS id.": "ILIAS Kursmitglieder ohne ILIAS ID"
      #Task
      "New task": "Aufgabe erstellen"
      "Search tasks": "Suche Aufgaben"
      "Filter me.": "Filtere mich."
      "Open": "Offen"
      "In progress": "In Bearbeitung"
      "Waiting": "Wartet"
      "Done": "Erledigt"
      "very low": "Sehr niedrig"
      "low": "Niedrig"
      "medium": "Mittel"
      "high": "Hoch"
      "critical": "Kritisch"
      "changed": "geändert"
      "created": "erstellt"
      "Select user": "Benutzer auswählen"
      #Placeholder
      "Add a tag": "Stichwort hinzufügen"
      "max. 160 chars": "Maximal 160 Zeichen"
      "(New Tag)": "(Neues Stichwort)"
      #Dashboard
      "No tasks. Time for coffee.": "Keine Aufgaben, ab nach Hause!"
      "Gigs today": "Heutige Termine"
      #Search results
      "email": "E-Mail"
      "rank": "Rang"
      "user_name": "Benutzername"
      "name": "Name"
      "number": "Nummer"
      "deal_number": "Vertragsnummer"
      "source": "Kontakt über"
      "tags": "Stichwort"
      "phones": "Telefon"
      "addresses": "Adresse"
      "mails": "E-Mail"
      "content": "Inhalt"
      "booking_type": "Auftragstyp"
      'Parameter suggestions': "Feldvorschläge"
      "Start search": "Suche starten"
      "Search box": "Suchfenster"
      "Select a translation": "Übersetzung auswählen"
      "No location": "Keine Räumlichkeit"
      #Sources
      "Fair": "Messe"
      "Personal contact": "Persönlicher Kontakt"
      "Contact form": "Online-Kontaktformular"
      "Online survey": "Online-Infoveranstaltung bzw- Umfrage"
      "Survey": "Umfrage"
      "Customer import": "Kontaktimport"
      "ImportStart": "Kontaktimport wird gestartet."
      "ImportSuccess": "{{ length }} Kontakte wurden angelegt."
      "Employment agency": "Arbeitsamt"
      "Federal Armed Forces": "Bundeswehr"
      "Advertising": "Werbung"
      "License": "BR: autom. Lizenzanlage"
      "Data source for BDSG": "Datenherkunft für BDSG"
      "Change recipient": "Empfänger ändern"
      "Reference number" : "Reservierungsnummer"
      #Rebook
      "Gigs without measure": "Umgebuchte Termine"
      "Rebooking for": "Umbuchung für"
      "Rebook": "Umbuchung"
      "Matching gigs": "Verfügbare Termine"
      "Gig search": "Terminsuche"
      "Start search": "Suche starten"
      "GigSearchSuccess": "{{ length }} Termine gefunden"
      "UnsubscribeMsg": "{{ customerName }} erfolgreich aus {{ gigName }} ausgeschrieben."
      "SubscribeMsg": "{{ customerName }} erfolgreich in {{ gigName }} eingeschrieben."
      "true": "Ja"
      "false": "Nein"
      #Gigger
      "Gigger": "Einzelterminbuchung"
      "Select customer": "Kontakt auswählen"
      "Select a deal": "Vertrag auswählen"
      "Select an underlying measure": "Grundmaßnahme auswählen"
      "Select a target measure": "Zielmaßnahme auswählen"
      # Term
      "Create term": "Neue Klausel"
      "Term name": "Klauselname"
      "Wording": "Wortlaut"
      "Last change": "Letzte Änderung"
      "Term list": "Klauselkatalog"
      # Template
      "Create template": "Neuer Entwurf"
      "Template name": "Entwurfsname"
      "Add bundle": "Klauselgebund hinzufügen"
      "Add term": "Klausel hinzufügen"
      #Errors
      'cash_discount': 'Skontoprozentsatz'
      'revenue_account': 'Erlöskonto'
      'cost_unit': 'Kostenstelle'
      'invoices': 'Rechnungen'
      'debits': 'Positionen'
      'Required': 'nicht angegeben.'
      'Action' : 'Aktion'
      'title': 'Titel'
      'comments': 'Kommentare'
      'achievements': 'Qualifikationen'
      'credentials': 'Zugangsdaten'
      #Hopes
      'Hopes': 'Hoffnungen'
      'Hoper': 'Hoffender'
      'Company name': 'Unternehmen'
      'Hope name': 'Hoffnungsanlass'
      'Edit hopes': 'Hoffnungen ergründen'
      'Guess': 'Einschätzen'
      'Old guesses': 'Einschätzungsverlauf'
      'Contribution margin': 'Deckungsbeitrag'
      'Guess value': 'Schätzwert'
      'Start date': 'Startdatum'
      'Months': 'Monate'
      'raised': 'Erwacht'
      'vague': 'Vage'
      'confident': 'Zuversichtlich'
      'justified': 'Gerechtfertigt'
      'lost': 'Erloschen'
      'Guess age': 'Schätzalter'
      'Total': 'Summe'
      'Timespan': 'Schätzperiode'
      'by': 'von'
      'Turnovers': 'Umsätze'
      'Show turnovers': 'Zeig mir das Geld'
      #maXvis
      'Contents': "maXvis-Ziele"
      'Layouts': "Veranstalter-Layout"
      #Catalog
      'Catalog': 'Katalog'
      'Template list': 'Entwurfskatalog'
      'Revenue account list': 'Erlöskontenkatalog'
      'Cost unit list': 'Kostenträgerkatalog'
      'Region list': 'Regionskatalog'
      'Agenda list': 'Agendenkatalog'
      'Module list': 'Modulkatalog'
      'Sequence list': 'Sequenzkatalog'
      'Course list': 'Kurskatalog'
      'Draft list': 'Vorlagenkatalog'
      'Achievement list': 'Qualifikationskatalog'
      'Order list': 'Shop Buchungen'
      'Ordered': 'Gekauft am'
      'assigned date': 'gebucht am'
      #Admin
      "Create revenue account": "Neues Erlöskonto"
      "Revenue account name": "Erlöskontobezeichnung"
      "Account number": "Erlöskontonummer"
      "Tax code": "Steuerschlüssel"
      "Tax rate": "Steuersatz"
      "Create cost unit": "Neuer Kostenträger"
      "Cost unit name": "Kostenträgerbezeichung"
      "Unit number": "Kostenträgernummer"
      # LMS
      "LmsCourse": "ILIAS Kurs"
      "LMS course": "ILIAS Kurs"
      "LMS course list": "ILIAS Kurskatalog"
      "LMS courses": "ILIAS Kurse"
      "LMS draft": "ILIAS Kursvorlage"
      "LMS draft list": "ILIAS Kursvorlagenkatalog"
      "LMS drafts": "ILIAS Kursvorlagen"
      "LMS category": "ILIAS Kategorie"
      "LMS category list": "ILIAS Kategoriekatalog"
      "LMS categories": "ILIAS Kategorien"
      "Please fill out the required subscription fields.": "Bitte füllen Sie die benötigten Abonnement-Felder aus."
      "Please fill out the form fields. All fields are required.": "Bitte füllen Sie alle Formularfelder aus."
      "ILIAS name": "ILIAS Name"
      "ILIAS password": "ILIAS Passwort"
      "Please wait": "Bitte warten"
      "Member": "ILIAS Kursteilnehmer"
      "Members": "ILIAS Kursteilnehmer"
      "Tutor": "ILIAS Kurstutor"
      "Tutors": "ILIAS Kurstutoren"
      "Type": "Typ"
      "Types": "Typen"
      "Subscription": "Abonnement"
      "Subscriptions": "Abonnements"
      "Subscription period": "Abo-Zeitraum"
      "Subscription quantity": "Anzahl der Abo-Zeiträume"
      "Charging period": "Abrechnungszeitraum"
      "Charging quantity": "Anzahl der Abo-Zeiträume"
      "Charged till": "Abgerechnet bis"
      "Charging type": "Abrechnungsart"
      "Add a tag": "Stichwort hinzufügen"
      "in advance": "Im Voraus"
      "in arrears": "Im Nachhinein"
      "days": "Tage"
      "weeks": "Wochen"
      "months": "Monate"
      "quarters": "Quartale"
      "years": "Jahre"
      "In advance": "Im Voraus"
      "In arrears": "Im Nachhinein"
      "Days": "Tage"
      "Weeks": "Wochen"
      "Months": "Monate"
      "Quarters": "Quartale"
      "Years": "Jahre"
      "End of year": "Jahresende"
      "End of month": "Monatsende"
      "End of century": "Jahrhundertwechsel"
      "End of decade": "Dekadenwechsel"
      "End of ages": "Ende des Zeitalters"
      "End of time": "Sankt Nimmerleinstag"
      "end of year": "Jahresende"
      "end of month": "Monatsende"
      "end of century": "Jahrhundertwechsel"
      "end of decade": "Dekadenwechsel"
      "end of ages": "Ende des Zeitalters"
      "end of time": "Sankt Nimmerleinstag"
      "Expiration Type": "Ablaufart"
      "Status date": "Statusdatum"
      "Achievable from": "Erreichbar ab"
      "Achievable till": "Erreichbar bis"
      "Score": "Punktestand"
      "Items": "Einträge"
      "Change date": "Änderungsdatum"
      "Required achievements": "Benötigte Qualifikationen"
      "Required points": "Benötigte Punkte"
      "Point type": "Punktetyp"
      "Point type name": "Punktetypbezeichnung"
      "Point value": "Punktzahl"
      "Point types": "Punktetypen"
      "Point achieved": "Punkterreichungsdatum"
      "Exchange rates": "Wechselkurse"
      "Exchange rate": "Wechselkurs"
      "equals": "entspricht"
      "Expirable": "Verfallbar"
      "temporarily available": "vorübergehend verfügbar"
      "not available": "nicht verfügbar"
      "unlimited": "unbegrenzt"
      "empty": "leer"
      "Invalid date": "Ungültiges Datum"
      "Expired score": "Abgelaufene Punkte"
      "Rated score": "Bewertete Punkte"
      "Expiration date": "Ablaufdatum"
      "Reason": "Grund"
      "Changed": "Geändert"
      "Download members": "ILIAS Kursmitglieder herunterladen"
      # Picks
      "pick": "Begriffssammlung"
      "picks": "Begriffssammlungen"
      "Picks": "Begriffssammlungen"
      "Create pick": "Neue Begriffssammlung"
      "Pick name": "Name der Begriffssammlung"
      "Pick values": "Begriffe"
      "Pick list": "Begriffssammlungen"
      #Customer import
      "Crowd": "Mischpoke"
      "Crowd edit": "Mischpoke editieren"
      "Crowd name": "Name der Mischpoke"
      "Crowd create": "Mischpoke erstellen"
      "Select csv to import": "CSV zum Hochladen auswählen"
      "Edit individual": "Bearbeiten"
      "errors found": "Fehler gefunden"
      "Individual create": "Individuum erstellen"
      "Individual edit": "Individuum bearbeiten"
      "import": "Import"
      "imported": "Importiert"
      "assimilate": "Integrieren"
      "assimilated": "Integriert"
      "First page": "Erste Seite"
      "Last page": "Letzte Seite"
      "Individual found by same name": "Kontakt mit dem selben Namen."
      "Individual found by same address": "Kontakt mit derselben Adresse."
      "Individual found by same mail": "Kontakt mit derselben E-Mail."
      "Errors": "Fehler"
      "Matched customer": "Ähnliche Kontakte"
      "Unmatched customer": "Unähnliche Kontakte"
      "Assign a deal": "Vertrag zuweisen"
      "Quick filter": "Schnell-Filter"
      "Quick action": "Schnell-Aktion"
      "Bad individuals": "Fehlerhafte Individuen"
      "Number of bad individuals": "Anzahl der fehlerhaften Individuen"
      "Number of individuals": "Anzahl der Individuen"
      "Automatic assimilation": "Auto-Integration"
      "Starting assimilation.", "Auto-Integration wird gestartet."
      "Let's go", "Auf gehts"
      "Assimilation complete.", "Auto-Integration abgeschlossen"
      "Assimilation failed.", "Auto-Integration abgebrochen"
      "Resistance is futile", "Das war einfach"
      "Server error": "Serverfehler, bitte aktualisieren Sie die Anwendung und informieren die IT."
      "User has been changed while editing.": "Der Benutzer wurde von jemand anderem bereits geändert."
      "Customer has been changed while editing.": "Der Kontakt wurde von jemand anderem bereits geändert."
      "Deal has been changed while editing.": "Der Vertrag wurde von jemand anderem bereits geändert."
      "ILIAS course has been changed while editing.": "Der ILIAS Kurs wurde von jemand anderem bereits geändert."
      "Real estate agent declarations": "Immobilienmakler Bescheinigungen"
      "Real estate manager declarations": "Wohnungseigentumsverwalter Bescheinigungen"
    })

    $translateProvider.preferredLanguage('gp_de')


angular.module('SkillControl')
.config(['$translateProvider', translationConfig])