class adminCostUnit
  constructor: ->
    return []

class costUnitConfig
  constructor: ($stateProvider, $provide) ->
    $stateProvider
    .state 'main.allocation.gigger',
      url: "/gigger",
      templateUrl: "allocation/gigger/gigger.tpl.html",
      controller: "allocationGiggerController",
      data:
        pageTitle: 'Start gigging',
        pageName: 'View gigger'

class allocationGigger
  constructor: ($state, $scope, Customer, Measure, Gig, $translate, toastr, $filter) ->

    $scope.deals = []
    $scope.measures = []
    $scope.gigs = []
    $scope.gigMeasure = {}
    $scope.attendeeStatus = Gig.attendeeStatus

    $scope.getMeasures = (deal) ->
      $scope.measures = deal.measures if deal

     # Get gig dates for agendas
    getGigs = (measure_id) ->
      $scope.gigs.length = 0
      measureGigPromise = Measure.service.one(measure_id).one('gigs').get()
      measureGigPromise.then (data) ->
        _.forEach(data, (value, key) ->
          $scope.getGigStatus(value)
        )
        $scope.gigs = data
      , (response) ->
        toastr.error(response.error)

    $scope.getGigMeasure = (measure_id) ->
      Measure.service.one(measure_id).get().then (response) ->
        $scope.gigMeasure = response
        getGigs(response._id)

    getDeals = (customer_id) ->
      Customer.service.one(customer_id).one('deals').get().then (response) ->
        $scope.deals = response

    $scope.prepareDeal = (_deal) ->

      measure_names = _.map(_deal['measures'], 'name').join(',')

      return "#{_deal['deal_number']} - #{measure_names}"


    $scope.selectCustomer = (model) ->
      $scope.gigs.length = 0
      delete $scope.selectedMeasure
      $scope.measures.length = 0
      delete $scope.selectedDeal
      $scope.deals.length = 0
      getDeals(model._id)

    $scope.getGigStatus = (gig) ->
      if _.isObject(gig)
        attendee = _.filter(gig.attendees, 'attendee_id': $scope.customer_data._id)
        if attendee.length
          gig['subscribed'] = true
          gig['attendee_status'] = attendee[0].status
        if not attendee.length
          gig['attendee_status'] = "warranted"

    $scope.saveGig = (gig) ->
      if gig.subscribed
        _attendee =
          status: gig.attendee_status
          attendee_id: $scope.customer_data._id
          measure_id: $scope.selectedMeasure._id
          deal_id: $scope.selectedDeal._id
        gig.attendees.push(_attendee)

        Gig.service.one(gig._id).customPUT(gig).then (response) ->
          $translate('SubscribeMsg',
          {
            gigName: response.name,
            customerName: $filter('fullName')($scope.customer_data)
          }).then (text) ->
            toastr.success text

      else
        _.remove(gig.attendees, {
        'attendee_id': $scope.customer_data._id
        })

        delete gig['attendee_status']

        Gig.service.one(gig._id).customPUT(gig).then (response) ->
          $translate('UnsubscribeMsg',
          {
            gigName: response.name,
            customerName: $filter('fullName')($scope.customer_data)
          }).then (text) ->
            toastr.success text


angular.module('allocation.gigger', new adminCostUnit())
.config(['$stateProvider', '$provide', costUnitConfig])
.controller('allocationGiggerController', ['$state', '$scope', 'Customer', 'Measure', 'Gig', '$translate', 'toastr', '$filter', allocationGigger])