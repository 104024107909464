class scAuth
  constructor: ->
    return []

class authConfig
  constructor: ($stateProvider) ->
    $stateProvider
    .state('main.user',
      abstract: true
      templateUrl: 'auth/user.tpl.html'
      )

    .state('main.user.list',
      url: '/user'
      templateUrl: 'auth/user.list.tpl.html'
      controller: 'userListController'
      data:
        pageTitle: 'User Admin :: list'
        pageName: 'List of Users')
    .state('main.user.edit',
      url: '/user/:userId'
      templateUrl: 'auth/user.edit.tpl.html'
      controller: 'userEditController'
      data:
        pageTitle: 'User Admin :: edit'
        pageName: 'Edit a User')
    .state('login',
      url: '/login'
      templateUrl: 'auth/user.login.tpl.html'
      controller: 'loginController'
      data:
        pageTitle: 'User :: login'
        pageName: 'Login to SkillControl')
    .state 'main.user.database',
      url: '/database'
      controller: 'userDatabaseController'
      templateUrl: 'auth/user.database.tpl.html'
      data:
        pageTitle: 'User Admin :: database'
        pageName: 'Perform database actions'


class userList
  constructor: ($scope, User) ->
    userPromise = User.one().get()

    userPromise.then (data) ->
      $scope.data = data
      return

    $scope.remove = (_id) ->
      alert _id
      return

    $scope.searchUser = ->
      searchPromise = User.one().get(search: $scope.search)
      searchPromise.then (data) ->
        $scope.data = data
        return
      return
    return


class userEdit
  constructor: ($scope, $state, $stateParams, User, toastr, Restangular) ->

    $scope.submit = ->
      # just decide between post and put
      if $stateParams.userId
        $scope.data.put().then ->
          toastr.success $scope.data.full_name + ' edited.', 'Edit successful.'
          $state.go 'main.user.list'
          return
      else
        User.post($scope.data).then (->
          toastr.success $scope.data.full_name + ' created.', 'Create successful.'
          $state.go 'main.user.list'
          return
        ), (response) ->
          console.log 'error', data
          $scope.errors = _.groupBy(response.data.errors, 'name')
          return

    $scope.remove = ->
      User.one($scope.data._id).customDELETE().then ->
      toastr.error $scope.data.full_name + ' has been neutralized.', 'Execution successful.'
      $state.go 'main.user.list'

    if $stateParams.userId
      #user = Restangular.one('user', $stateParams.userId)
      User.one($stateParams.userId).get().then (data) ->
        $scope.data = data
        return

    $scope.addCustomer = (model) ->
      model.customer_id = model._id
      $scope.data.customers.push(model)
      $scope.customer = {}

class userDatabase
  constructor: ($scope, Admin) ->
    $scope.submit = ->
      adminPromise = Admin.one($scope.size).get()
      adminPromise.then (data) ->
        console.log data

class login
  constructor: ($scope, $rootScope, $state, Auth, ApiAuth, toastr) ->
    ###*
    #   this logs a user into the site
    ###
    $scope.data = {}

    $scope.login = ->
      toastr.info 'Please be patient while we are checking your credentials.', 'Login in progress...'
      loginPromise = ApiAuth.one('login').customPOST($scope.data)
      loginPromise.then () ->
        statePromise = Auth.one('state').get()
        statePromise.then (authState) ->
          $rootScope.setState(authState)
          toastr.success "Welcome to the wonderful world of education, #{ $rootScope.state.user.full_name }",
            "Login successful"

          $state.go('main.dashboard')


angular.module('sc.auth', new scAuth())
.config(['$stateProvider', authConfig])
.controller('userListController', ['$scope', 'User', userList])
.controller('userEditController', ['$scope', '$state', '$stateParams', 'User', 'toastr', 'Restangular', userEdit])
.controller('userDatabaseController', ['$scope', 'Admin', userDatabase])
.controller('loginController', ['$scope', '$rootScope', '$state', 'Auth', 'ApiAuth', 'toastr', login])