class dTree
  constructor: ->
    return []

# Collapsable tree for draft and measure structure
class dTreeView
  constructor: ->
    return {
      restrict: 'E'
      scope:
        model: '='
        height: '='
        width: '='
      link: (scope) ->
        margin =
          top: 20
          right: 0
          bottom: 20
          left: 0
        width = scope.width - (margin.right) - (margin.left)
        height = scope.height - (margin.top) - (margin.bottom)
        i = 0
        duration = 750
        root = undefined
        tree = d3.layout.tree().size([
          height
          width
        ])
        diagonal = d3.svg.diagonal().projection((d) ->
          [
            d.y
            d.x
          ]
        )
        svg = d3.select('d-tree-view').append('svg')
        .attr('width', width + margin.right + margin.left)
        .attr('height', height + margin.top + margin.bottom).append('g')
        .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')


        colorChart =
        'course': '#ffb606'
        'module': '#62cb31'
        'sequence': 'lightsteelblue'
        'agenda': '#9b59b6'

        getStyle = (item) ->
          if item.sequences
            colorChart.course
          else if item.modules
            colorChart.sequence
          else if item.agendas
            colorChart.module
          else if item.lessons
            colorChart.agenda
          else
            'rgb(133, 161, 255)'

        update = (source) ->
          # Compute the new tree layout.
          nodes = tree.nodes(root).reverse()
          links = tree.links(nodes)
          # Normalize for fixed-depth.
          nodes.forEach (d) ->
            d.y = d.depth * 210 + 100

          # Update the nodes…
          node = svg.selectAll('g.node').data(nodes, (d) ->
            d.id or (d.id = ++i)
          )
          # Enter any new nodes at the parent's previous position.
          nodeEnter = node.enter().append('g').attr('class', 'node').attr('transform', (d) ->
            'translate(' + source.y0 + ',' + source.x0 + ')'
          ).on('click', click)
          nodeEnter.append('circle').attr('r', 1e-6).style 'fill', (d) ->
            if d._children then '#e74c3c' else getStyle(d)
          nodeEnter.append('text').attr('x', (d) ->
            if d.children or d._children then -10 else 10
          ).attr('dy', '.35em').attr('text-anchor', (d) ->
            if d.children or d._children then 'end' else 'start'
          ).text((d) ->
            d.name
          ).style 'fill-opacity', 1e-6
          # Transition nodes to their new position.
          nodeUpdate = node.transition().duration(duration).attr('transform', (d) ->
            'translate(' + d.y + ',' + d.x + ')'
          )
          nodeUpdate.select('circle').attr('r', 8).style 'fill', (d) ->
            if d._children then '#e74c3c' else getStyle(d)
          nodeUpdate.select('text').style 'fill-opacity', 1
          # Transition exiting nodes to the parent's new position.
          nodeExit = node.exit().transition().duration(duration).attr('transform', (d) ->
            'translate(' + source.y + ',' + source.x + ')'
          ).remove()
          nodeExit.select('circle').attr 'r', 1e-6
          nodeExit.select('text').style 'fill-opacity', 1e-6
          # Update the links…
          link = svg.selectAll('path.link').data(links, (d) ->
            d.target.id
          )
          # Enter any new links at the parent's previous position.
          link.enter().insert('path', 'g').attr('class', 'link').attr 'd', (d) ->
            o =
              x: source.x0
              y: source.y0
            diagonal
              source: o
              target: o
          # Transition links to their new position.
          link.transition().duration(duration).attr 'd', diagonal
          # Transition exiting nodes to the parent's new position.
          link.exit().transition().duration(duration).attr('d', (d) ->
            o =
              x: source.x
              y: source.y
            diagonal
              source: o
              target: o
          ).remove()
          # Stash the old positions for transition.
          nodes.forEach (d) ->
            d.x0 = d.x
            d.y0 = d.y

        # Toggle children on click.

        click = (d) ->
          if d.children
            d._children = d.children
            d.children = null
          else
            d.children = d._children
            d._children = null
          update d

        scope.$watchCollection 'model', ->
          if scope.model.tree
            collapse = (d) ->
              if d.children
                d._children = d.children if d.agendas
                d.children.forEach collapse
                d.children = null if d.agendas

            root = scope.model.tree
            root.x0 = height / 2
            root.y0 = 0
            root.children.forEach collapse if root.children
            update root

        d3.select(self.frameElement).style 'height', '800px'
    }

angular.module('sc.tree', new dTree())
.directive('dTreeView', [dTreeView])