initialTreeData = [{
  'name': 'New picks'
  'parent': 'null'
  'childrenType': 'picks'
}]

class scStage
  constructor: ->
    return [
      'restangular'
      'ui.sortable'
      'ui.bootstrap'
      'ui.router'
      'sc.directive'
      'sc.factory'
    ]

class stageConfig
  constructor: ($stateProvider, $provide) ->

    $stateProvider
    .state 'main.stage',
      abstract: true,
      url: '/stage'
      templateUrl: "stage/stage.tpl.html",
      controller: 'stageController',
      data: {}

    .state 'main.stage.picks',
      url: '/picks'
      abstract: true
      template: "<div ui-view=''></div>"

    .state 'main.stage.picks.create',
      url: "/create"
      templateUrl: "stage/picks.tpl.html",
      controller: "stagePicksCreateController",
      data:
        pageTitle: 'Create a Picks',
        pageName: 'View Picks'

    .state 'main.stage.picks.edit',
      url: "/edit/:pickId",
      templateUrl: "stage/picks.tpl.html",
      controller: "stagePicksEditController",
      data:
        pageTitle: 'Edit a Picks',
        pageName: 'View Picks'

class stage
  constructor: ($state, $scope) ->
    $scope.$on '$stateChangeSuccess', (event, toState, toParams, fromState, fromParams) ->
      $scope.pageTitle = toState.data.pageTitle if angular.isDefined(toState.data.pageTitle)
      return



class stagePicksCreate
  constructor: ($scope, $state, Picks, toastr) ->

    $scope.picks = {}
    $scope.pick = {
      name: ''
      pick_values: []
    }
    $scope.pickVals = []

    $scope.getPicks = ->
      Picks.picksService.one().get().then (data) ->
        $scope.picks = data.picks
        # console.log $scope.picks
      , (response) ->
        $scope.errors = response.data.errors
        toastr.error(response.error)
    $scope.getPicks()

    $scope.savePicks = ->

      Picks.picksService.post($scope.pick)
      .then (data) ->
        toastr.success(data.name + " was born.")
        $state.go 'main.stage.picks.edit', {'pickId': data._id}
      , (response) ->
        $scope.errors = response.data.errors
        toastr.error(response.error)


class stagePicksEdit
  constructor: ($scope, $stateParams, $state, Picks, toastr) ->

    if $stateParams.pickId?.length
      Picks.picksService.one($stateParams.pickId).get().then (data) ->
        $scope.pick = data
        $scope.pickVals = data.pick_values
      , (response) ->
        toastr.error(response.error)

    $scope.clearPicks = ->
      $state.go 'main.stage.picks.create'
      toastr.success("Picks wiped.", "Wipe successful.")

    $scope.clonePicks = ->
      $scope.pick._id = ''
      $scope.pick.name = $scope.pick.name + " - Clone"
      $scope.savePicks()

    $scope.selectPicks = (picks) ->
      $state.go("main.stage.picks", {pickId: picks._id})
      return

    $scope.splitter = (obj) ->
      if obj && obj.split
        sp = obj.split(",")
        for e of sp
          sp[e] = sp[e].trim()
        return _.uniqBy(sp)
      else return null

    $scope.savePicks = ->
      $scope.pick.pick_values = $scope.splitter($scope.pickVals)

      if $scope.pick._id
        Picks.picksService.one($scope.pick._id)
        .customPUT($scope.pick)
        .then (data) ->
          $scope.pick = data
          toastr.success(data.name + " saved.", "Edit successful.")
          $state.go 'main.stage.picks.edit', {'pickId': data._id}
        , (response) ->
          $scope.errors = response.data.errors
          toastr.error(response.error)
      else
        Picks.picksService.post($scope.pick)
        .then (data) ->
          toastr.success(data.name + " was born.")
          $state.go 'main.stage.picks.edit', {'pickId': data._id}
        , (response) ->
          $scope.errors = response.data.errors
          toastr.error(response.error)

    $scope.deletePicks = ->
      Picks.picksService.one($scope.pick._id)
      .customDELETE()
      .then (data) ->
        toastr.success(data.name + " was eliminated.", "Good bye my fellow picks.")
        $state.go 'main.stage.picks.create'
      , (response) ->
        $scope.errors = response.data.errors
        toastr.error(response.error)


angular.module('sc.stage', new scStage())
.config(['$stateProvider', '$provide', stageConfig])
.controller('stageController', ['$state', '$scope', stage])
.controller('stagePicksCreateController', ['$scope', '$state', 'Picks', 'toastr', stagePicksCreate])
.controller('stagePicksEditController', ['$scope', '$stateParams', '$state', 'Picks', 'toastr', stagePicksEdit])