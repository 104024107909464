class translationConfig
  constructor: ($translateProvider) ->
    $translateProvider.translations('dev', {
      PageTitle: 'All your base are belong to us!'
      PageSubtitle: 'Welcome to SkillControl the dominating application to control your educational enterprise.'
      Users: 'Users'
      Customers: 'Customers'


    })

    $translateProvider.useSanitizeValueStrategy(null)

angular.module('SkillControl')
.config(['$translateProvider', translationConfig])