MALE = 'male'
FEMALE = 'female'

class scFilter
  constructor: ->
    return []

class fontIcon
  constructor: ->
    types =
      'birthplace': 'fa fa-hospital-o fa-2x'
      'registered': 'fa fa-map-marker fa-2x'
      'home': 'fa fa-home fa-2x'
      'office': 'fa fa-building-o fa-2x'
      'billing': 'fa fa-credit-card fa-2x'
      'shipping': 'fa fa-paper-plane-o fa-2x'
      'parking': 'fa fa-car fa-2x'
      'entrance': 'fa fa-flag fa-2x'
      'personal': 'fa fa-home fa-2x'
      'public': 'fa fa-globe fa-2x'
      'business': 'fa fa-building-o fa-2x'
      'mobile': 'fa fa-mobile-phone fa-2x'
      'fax': 'fa fa-fax fa-2x'
      'Clear': 'wi wi-day-sunny-overcast'
      'Clouds': 'wi wi-cloudy'
      'Rain': 'wi wi-rain'
      'Snow': 'wi wi-snow'
      'Lightning': 'wi-lightning'
      'Sunny': 'wi wi-day-sunny'
      'internal': 'pe-7s-angle-down-circle fa-2x'
      'external': 'pe-7s-angle-up-circle fa-2x'
      'Mist': 'wi wi-day-fog'
      'Drizzle': 'wi wi-day-sprinkle'
      'Thunderstorm': 'wi wi-day-thunderstorm'
      'Customer': 'fa fa-child fa-2x'
      'Company': 'fa fa-building-o fa-2x'
      'User': 'fa fa-user fa-2x'
      'Gig': 'fa fa-calendar fa-2x'
      'Achievement': 'fa fa-trophy fa-2x'
      'Skill': 'fa fa-trophy fa-2x'
      'RevenueAccountSmall': 'fa fa-area-chart fa-1x'
      'CostUnitSmall': 'fa fa-money fa-1x'
      'Deal': 'fa fa-pencil fa-2x'
      'Location': 'mega-octicon octicon-milestone'
      'Draft': 'fa fa-share-alt fa-2x'
      'Course': 'fa fa-graduation-cap fa-2x'
      'Sequence': 'fa fa-list-ol fa-2x'
      'Module': 'fa fa-book fa-2x'
      'Agenda': 'fa fa-list-alt fa-2x'
      'Lesson': 'fa fa-paper-plane fa-2x'
      'Document': 'fa fa-file fa-2x'
      'Region': 'fa fa-globe fa-2x'
      'Measure': 'fa fa-university fa-2x'
      'Booking': 'fa fa-shopping-cart fa-2x'
      'Mandate': 'fa fa-credit-card fa-2x'
      'Grant': 'fa fa-gift fa-2x'
      'Template': 'fa fa-pencil-square fa-2x'
      'Term': 'fa fa-file-text-o fa-2x'
      'Dashboard': 'glyphicon glyphicon-dashboard fa-2x'
      'Edit names': 'fa fa-edit fa-2x'
      'Last Comment': 'fa fa-comment-o fa-2x'
      'Edit addresses': 'fa fa-map-marker fa-2x'
      'Edit other': 'fa fa-cogs fa-2x'
      'Edit schedule': 'fa fa-calendar'
      'Comments': 'fa fa-comment-o fa-2x'
      'Edit locations': 'fa fa-location-arrow fa-2x'
      'Edit basic': 'mega-octicon octicon-milestone'
      'Edit related': 'fa fa-link fa-2x'
      'wifi': 'fa fa-wifi fa-2x'
      'ILIAS': 'fa fa-leanpub fa-2x'
      'website': 'fa fa-html5 fa-2x'
      'Edit busy': 'fa fa-calendar-o fa-2x'
      'Edit hopes': 'fa fa-binoculars fa-2x'
      'Turnovers': 'fa fa-area-chart fa-2x'
      'Crowd': 'fa fa-file-excel-o fa-2x'

    return (type) ->
      if type of types
        types[type]
      else
        'fa fa-beer fa-2x'

class age
  constructor: ->
    return (birthday) ->
      if birthday
        ageDifMs = Date.now() - new Date(birthday).getTime()
        ageDate = new Date(ageDifMs)
        #milliseconds from epoch
        return Math.abs(ageDate.getUTCFullYear() - 1970)

class isBirthday
  constructor: ->
    return (birthday) ->
      if birthday
        dateNow = new Date
        checkDay = undefined
        checkMonth = undefined
        _birthday = new Date(birthday)
        checkDay = dateNow.getDate() is _birthday.getDate()
        checkMonth = dateNow.getMonth() is _birthday.getMonth()
        checkDay and checkMonth
      else
        false

class salutationLetter
  constructor: ->
    title_map = {}

    get_default_title = (sex) ->
      switch sex
        when MALE
          'r Herr'
        when FEMALE
          ' Frau'

    title_map[MALE] =
      'Dr.': 'r Herr Dr.'
      'Prof.': 'r Herr Prof.'
      'Prof. Dr.': 'r Herr Prof. Dr.'
      'Graf': 'r Graf'
      'Freiherr': 'r Herr v.'
      'Baron': 'r Baron'
      'Herzog': 'r Herzog'

    title_map[FEMALE] =
      'Dr.': ' Frau Dr.'
      'Prof.': ' Frau Prof.'
      'Prof. Dr.': ' Frau Prof Dr.'
      'Gräfin': ' Gräfin'
      'Freiin': ' Frau v.'
      'Freifrau': ' Frau v.'
      'Baronin': ' Baronin'
      'Herzogin': ' Herzogin'

    return (data) ->
      if data.sex != MALE and data.sex != FEMALE
        'Sehr geehrte Damen und Herren'
      else if data.title of title_map[data.sex]
        'Sehr geehrte' + title_map[data.sex][data.title] + ' ' + data.last_name
      else
        'Sehr geehrte' + get_default_title(data.sex) + ' ' + data.last_name

class salutationSpeech
  constructor: ->
    get_default_title = (sex) ->
      switch sex
        when MALE
          'Herr'
        when FEMALE
          'Frau'

    title_map = {}

    title_map[MALE] =
      'Dr.': 'Herr Doktor'
      'Prof.': 'Herr Professor'
      'Prof. Dr.': 'Herr Professor'
      'Graf': 'Durchlaucht'
      'Freiherr': 'Hochwohlgeboren'
      'Baron': 'Hochwohlgeboren'
      'Herzog': 'Königliche Hoheit'

    title_map[FEMALE] =
      'Dr.': 'Frau Doktor'
      'Prof.': 'Frau Professorin'
      'Prof. Dr.': 'Frau Professorin'
      'Gräfin': 'Durchlaucht'
      'Freiin': 'Hochwohlgeboren'
      'Freifrau': 'Hochwohlgeboren'
      'Baronin': 'Hochwohlgeboren'
      'Herzogin': 'Königliche Hoheit'

    return (data) ->
      if data.sex != MALE and data.sex != FEMALE
        'Guten Tag'
      else if data.title of title_map[data.sex]
        'Guten Tag ' + title_map[data.sex][data.title] + ' ' + data.last_name
      else
        'Guten Tag ' + get_default_title(data.sex) + ' ' + data.last_name

class salutationAddress
  constructor: ->
    get_default_title = (sex) ->
      switch sex
        when MALE
          'Herrn\n'
        when FEMALE
          'Frau\n'

    title_map_old_academic = {}
    title_map_low_nobility = {}
    title_map_high_nobility = {}
    title_map_officials = {}
    title_map_new_academic = {}

    title_map_old_academic[MALE] =
      'Dr.': 'Herrn\nDr. '
      'Prof.': 'Herrn\nProf. '
      'Prof. Dr.': 'Herrn\nProf. Dr. '
      'Dipl.-Ing.': 'Herrn\nDipl.-Ing. '
      'Dipl.-Kfm.': 'Herrn\nDipl.-Kfm. '
      'Diplom-Betriebswirt': 'Herrn\nDiplom-Betriebswirt '
    title_map_old_academic[FEMALE] =
      'Dr.': 'Frau\nDr. '
      'Prof.': 'Frau\nProf. '
      'Prof. Dr.': 'Frau\nProf. Dr. '
      'Dipl.-Ing.': 'Frau\nDipl.-Ing. '
      'Dipl.-Kff.': 'Frau\nDipl.-Kff. '
      'Diplom-Betriebswirt': 'Frau\nDiplom-Betriebswirtin '
    title_map_low_nobility[MALE] =
      'Graf': 'Graf '
      'Baron': 'Baron '
    title_map_low_nobility[FEMALE] =
      'Gräfin': 'Gräfin '
      'Baronin': 'Baronin '
    title_map_high_nobility[MALE] =
      'Freiherr': 'Herrn '
      'Herzog': 'Herrn '
    title_map_high_nobility[FEMALE] =
      'Freiin': 'Frau '
      'Freifrau': 'Frau '
      'Herzogin': 'Frau '
    title_map_officials[MALE] =
      'Rechtsanwalt': 'Herrn Rechtsanwalt'
      'Vorstandsvorsitzender': 'Herrn Vorstandsvorsitzender '
    title_map_officials[FEMALE] =
      'Rechtsanwältin': 'Frau Rechtsanwältin'
      'Vorstandsvorsitzende': 'Frau Vorstandsvorsitzende '
    title_map_new_academic[MALE] =
      'B. A.': 'Herrn '
      'B. SC.': 'Herrn '
      'M. A.': 'Herrn '
      'M. SC.': 'Herrn '
    title_map_new_academic[FEMALE] =
      'B. A.': 'Frau '
      'B. SC.': 'Frau '
      'M. A.': 'Frau '
      'M. SC.': 'Frau '

    return (data) ->
      if !data.title || !data.sex then data.first_name + ' ' + data.last_name
      else if data.title of title_map_old_academic[data.sex]
        title_map_old_academic[data.sex][data.title] + data.first_name + ' ' + data.last_name
      else if data.title of title_map_low_nobility[data.sex]
        title_map_low_nobility[data.sex][data.title] + data.first_name + ' v. ' + data.last_name
      else if data.title of title_map_high_nobility[data.sex]
        title_map_high_nobility[data.sex][data.title] + data.first_name + ' ' + data.title + ' v. ' + data.last_name
      else if data.title of title_map_officials[data.sex]
        title_map_officials[data.sex][data.title] + data.first_name + ' ' + data.last_name
      else if data.title of title_map_new_academic[data.sex]
        title_map_new_academic[data.sex][data.title] + data.first_name + ' ' + data.last_name + ' ' + data.title
      else
        get_default_title(data.sex) + data.first_name + ' ' + data.last_name

class fullAddress
  constructor: ->
    city = ''
    country = ''
    return (address) ->
      if !address then 'No address.'
      else
        if address.country
          country = ' - ' + address.country
        if address.street and address.city_code and address.city_name
          city = address.street + ', ' + address.city_code + ' ' + address.city_name
        else if address.street and address.city_name
          city = address.street + ', ' + address.city_name
        else if address.city_code and address.city_name
          city = address.city_code + ' ' + address.city_name
        else if address.city_name
          city = address.city_name
        city + country

class partyFullAddress
  constructor: ($filter) ->
    return (addresses, address_type) ->
      address = _.filter(addresses, {'address_type': address_type})
      if address.length? && address[0]?
        return $filter('fullAddress')(address[0])
      return 'No address'


class partyAddress
  constructor: ($filter) ->
    return (addresses, address_type, key) ->
      address = _.filter(addresses, {'address_type': address_type})
      if address.length? && address[0]?
        return address[0][key]
      return "Not available"


class partyType
  constructor: () ->
    return (parties, party_type) ->
      party = _.filter(parties, {'party_type': party_type})
      if party.length? && party[0]?
        return party[0]
      return 'Party not found'

class fullName
  constructor: ->
    return (data) ->
      full_name = ''
      if !data
        'Insufficient data.'
      else
        if data.title
          full_name = data.title
        if data.first_name
          full_name = full_name + ' ' + data.first_name
        if data.last_name
          full_name = full_name + ' ' + data.last_name
        if data.name
          full_name = data.name
        full_name

class default_address
  constructor: ($filter) ->
    address = ''
    return (data) ->
      if data.default_address
        if data.default_address.address_type
          if data.default_address.recipient_type == 'Company'
            address = data.default_address.name + '\n'
            address += $filter('salutationAddress')(data) + '\n'
          else
            address = $filter('salutationAddress')(data) + '\n'
            address += 'c/o ' + $filter('salutationAddress')(data.default_address) + '\n'
          address += data.default_address.street + '\n'
          address += data.default_address.city_code + ' ' + data.default_address.city_name + '\n'
          if data.default_address.country
            address += data.default_address.country + '\n'
          return address


class naiveToLocal
  constructor: ->
    return (naive, format) ->
      # convert naive datetime as utc to timezone aware in browser specific format
      # momentXX.js needed for each locale
      locale = window.navigator.userLanguage || window.navigator.language
      moment.locale(locale)
      aware = moment.utc(naive)
      aware = aware.local()
      # formats can be looked up here: http://momentjs.com/
      # LT -> 11:30 AM
      # l -> 4/28/1017
      # ll -> Apr 28, 2017
      # LLLL -> Friday, April 11th 2017 11:30 AM
      if not format then format = 'L'
      return aware.format(format)

class strToDatetime
  constructor: ->
    return (naive, format) ->
      # convert naive datetime as utc to timezone aware in browser specific format
      # momentXX.js needed for each locale
      locale = window.navigator.userLanguage || window.navigator.language
      moment.locale(locale)
      aware = moment.utc(naive)
      # formats can be looked up here: http://momentjs.com/
      # LT -> 11:30 AM
      # l -> 4/28/1017
      # ll -> Apr 28, 2017
      # LLLL -> Friday, April 11th 2017 11:30 AM
      if not format then format = 'L'
      return aware.format(format)


class relatedCompany
  constructor: ->
    return (data, customer_id) ->
      if data
        _topic = _.filter(data.contacts, {'customer_id': customer_id})[0].topic
        return data.name + ' - ' + _topic

class currency
  constructor: ->
    return (number, currencyCode) ->
      currencies =
        USD: '$'
        GPB: '£'
        EUR: '€'

      thousand
      decimal
      format

      if currencyCode == 'USD'
        thousand = ','
        decimal = '.'
        format = '%s%v'
      else
        thousand = '.'
        decimal = ','
        format = '%v%s'

      accounting.formatMoney(number, currencies[currencyCode], 2, thousand, decimal, format)


class power
  constructor: ->
    return (exponent, base) ->
      result = Math.pow base, exponent
      return result


class orderObjectBy
  constructor: ($filter) ->
    return (collection, expression) ->
      # Convert object to array
      collection = _.toArray(collection)
      # Use angular filter to order
      $filter('orderBy')(collection, expression)


angular.module('sc.filter', new scFilter())
.filter('fontIcon', [fontIcon])
.filter('age', [age])
.filter('isBirthday', [isBirthday])
.filter('salutationLetter', [salutationLetter])
.filter('salutationSpeech', [salutationSpeech])
.filter('salutationAddress', [salutationAddress])
.filter('fullAddress', [fullAddress])
.filter('partyFullAddress', ['$filter', partyFullAddress])
.filter('partyAddress', ['$filter', partyAddress])
.filter('partyType', [partyType])
.filter('fullName', [fullName])
.filter('default_address', ['$filter', default_address])
.filter('naiveToLocal', [naiveToLocal])
.filter('strToDatetime', [strToDatetime])
.filter('relatedCompany', [relatedCompany])
.filter('currency', [currency])
.filter('power', [power])
.filter('orderObjectBy', ['$filter', orderObjectBy])