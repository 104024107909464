class scDashboard
  constructor: ->
    return []

class config
  constructor: ($stateProvider) ->
    $stateProvider
      .state 'main.dashboard',
      url: '/dashboard'
      controller: 'dashboardController'
      templateUrl: 'dashboard/dashboard.tpl.html'
      data: {
        pageTitle: 'Skill Control'
      }


class dashboard
  constructor: ($scope, $translate, $state, $rootScope, Config) ->
    $scope.change = ->
      $translate.use('dev')

    config = Config.one().get()
    config.then (data) ->
      $scope.config = data


angular.module('sc.dashboard', new scDashboard())
.config(['$stateProvider', config])
.controller('dashboardController', ['$scope', '$translate', '$state', '$rootScope', 'Config', dashboard])