class adminhighScore
  constructor: ->
    return []

class highScoreConfig
  constructor: ($stateProvider, $provide) ->

    $stateProvider
      .state 'main.admin.highScore',
      url: "/high_score",
      templateUrl: "admin/high_score/high_score.tpl.html",
      controller: "adminHighScoreController",
      data:
        pageTitle: 'Show the high score list',
        pageName: 'View high score'


class adminHighScore
  constructor: ($scope, $state, $stateParams, toastr, Stats) ->
    highScorePromise = Stats.highScoreService.one().get()
    highScorePromise.then (response) ->
      $scope.scores = response.scores


angular.module('admin.highScore', new adminhighScore())
.config(['$stateProvider', '$provide', highScoreConfig])
.controller('adminHighScoreController', ['$scope', '$state', '$stateParams', 'toastr', 'Stats', adminHighScore])