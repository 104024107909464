class shortGigInput
  constructor: ($timeout) ->
    return {
      restrict: 'E'
      templateUrl: 'allocation/directives/short_gig_input.tpl.html'
      scope:
        update: '&'
      controller: ($scope) ->
        $scope.gig =
          begin: new Date()
          end: new Date()
    }

class shortGigView
  constructor: ($timeout, Gig) ->
    return {
      restrict: 'E'
      templateUrl: 'allocation/directives/short_gig_view.tpl.html'
      scope:
        model: '='
        update: '&'
      controller: ($scope) ->
        Gig.service.one($scope.model._id).get().then (data) ->
          $scope.gig = data

    }

angular.module('sc.allocation')
.directive('shortGigInput', ['$timeout', shortGigInput])
.directive('shortGigView', ['$timeout', 'Gig', shortGigView])