class scControl
  constructor: ->
    return [
      'angular.filter'
    ]

class allocationConfig
  constructor: ($stateProvider) ->
    $stateProvider

    .state 'main.control',
      url: '/control'
      template: "<div ui-view=''></div>"
      controller: 'controlController',
      data: {}

    # list means extended search
    .state 'main.control.lists',
      url: '/lists'
      templateUrl: 'control/lists.tpl.html'
      controller: 'controlListsController'
      data: {
        pageTitle: 'Control :: lists'
        pageName: 'lists'
      }

    .state 'main.control.key_values',
      url: '/key_values'
      templateUrl: 'control/key_values.tpl.html'
      controller: 'controlKey_valuesController'
      data: {
        pageTitle: 'Control :: key value pairs'
        pageName: 'key value pairs'
      }
      resolve:{
        'picksData': (Picks) ->
          return Picks.picksPromise
      }

    .state 'main.control.booking',
      url: '/booking_control'
      templateUrl: 'control/booking_control.tpl.html'
      controller: 'controlBookingController'
      data: {
        pageTitle: 'Control: Booking'
        pageName: 'Booking'
      }




class control
  constructor: ($state, $scope) ->
    $scope.$on '$stateChangeSuccess', (event, toState, toParams, fromState, fromParams) ->
      $scope.pageTitle = toState.data.pageTitle if angular.isDefined(toState.data.pageTitle)
      $scope.current_user = toState.data.current_user if angular.isDefined(toState.data.pageTitle)
      return

class controlLists
  constructor: ($scope, $state, Control, Skill, toastr, uiGridConstants, $filter) ->
    # define searchable fields
    $scope.availableSearchParams = [
      {key: "last_name", name: "Last Name", placeholder: "Last Name"},
      {key: "first_name", name: "First Name", placeholder: "First Name"},
      {key: "mail_address", name: "E-Mail", placeholder: "E-Mail"},
      {key: "city_name", name: "City Name", placeholder: "City Name"},
      {key: "city_code", name: "City Code", placeholder: "City Code"}
    ]

    $scope.data =
      customers: []

    $scope.lang = "de"

    $scope.gridOptions =
      enableSorting: true
      paginationPageSize: 10
      enablePaginationControls: false
      enableGridMenu: true
      enableFiltering: true
      exporterMenuPdf: false
      columnDefs: [
        {
          enableFiltering: false,
          name: 'Sex',
          field: 'sex',
          enableCellEdit: false,
          headerCellFilter: 'translate'
          visible: false
        },
        {
          enableFiltering: false,
          name: 'Title',
          field: 'title',
          enableCellEdit: false,
          headerCellFilter: 'translate'
          visible: false
        },
        {
          enableFiltering: false,
          name: 'First name',
          field: 'first_name',
          enableCellEdit: false,
          headerCellFilter: 'translate'
          visible: false
        },
        {
          enableFiltering: false,
          name: 'Last name',
          field: 'last_name',
          enableCellEdit: false,
          headerCellFilter: 'translate'
          visible: false
        },
        {
          enableFiltering: false,
          name: 'Name',
          field: 'full_name',
          enableCellEdit: false,
          headerCellFilter: 'translate'
        },
        {
          enableFiltering: false,
          name: 'Mail',
          field: 'mails[0].mail_address',
          enableCellEdit: false,
          headerCellFilter: 'translate'
        },
        {
          enableFiltering: false,
          name: 'Phone',
          field: 'phones[0].number',
          cellFilter: 'tel',
          enableCellEdit: false,
          headerCellFilter: 'translate'
        },
        {
          name: 'city_name',
          field: 'addresses[0].city_name',
          enableCellEdit: false,
          headerCellFilter: 'translate'
        },
        {

          name: 'Adstop',
          field: 'ad_stop',
          enableCellEdit: false,
          headerCellFilter: 'translate',
          cellFilter: 'translate',
          filter: {
            type: uiGridConstants.filter.SELECT,
            selectOptions: [
              {value: 'true', label: 'Ja'},
              {value: 'false', label: 'Nein'}
            ]
          }
        },
        {
          name: 'city_code',
          field: 'addresses[0].city_code',
          enableCellEdit: false,
          headerCellFilter: 'translate'
        },
        {
          enableFiltering: false,
          name: 'Achievement',
          field: 'achievements[0].name',
          enableCellEdit: false,
          headerCellFilter: 'translate'
        },
        {
          name: 'Date',
          field: 'achievements[0].status_date',
          enableCellEdit: false,
          headerCellFilter: 'translate'
        },
        {
          name: 'Status',
          field: 'achievements[0].status',
          enableCellEdit: false,
          headerCellFilter: 'translate',
          cellFilter: 'translate',
          filter: {
            type: uiGridConstants.filter.SELECT,
            selectOptions: [
              {value: 'requested', label: 'angestrebt'},
              {value: 'refused', label: 'verweigert'},
              {value: 'started', label: 'begonnen'},
              {value: 'achieved', label: 'erreicht'},
              {value: 'cat-state', label: 'unbekannt'}
            ]
          }
        },
        {
          name: 'tags',
          field: 'tags.join(", ")'
          enableCellEdit: false,
          headerCellFilter: 'translate'
        },
        {
          enableFiltering: false,
          'name': '#',
          'field': '_id',
          'sortable': false,
          'cellTemplate': '<a data-ui-sref="main.customer.edit.dashboard({customerId: COL_FIELD })">
                            <i class="fa fa-edit fa-2x"></i></a>',
          'enableCellEdit': false,
          'width': '50'

        }

      ]
    $scope.gridOptions.onRegisterApi = (gridApi) ->
      $scope.gridApi2 = gridApi

    skills = []
    tags = []

    # get all skills on page load
    getSkills = ->
      Skill.service.one().get().then (response) ->
        _(response.skills).forEach((n) ->
          skills.push({'_id': n._id, name: n.name, type: 'skill'}))
        init()

    getSkills()

    getTags = ->
      Control.customerTagService.one().get().then (response) ->
        _(response.tags).forEach((n, index) ->
          tags.push({'_id': index, 'name': n, 'type': 'tag'}))
        init()

    getTags()

    # combine all tags and skills into one array
    init = ->
      $scope.searchItems = _.union(skills, tags)

    $scope.startSearch = ->
      $scope.searchPromise = Control.customerFilterService.post($scope.searchParams)
      $scope.searchPromise.then (response) ->
        for c in response.customers
          c['full_name'] = $filter('fullName')(c)
          c['_achievements'] = _.filter(c.achievements, {'skill_id': $scope.searchParams.skills[0]})
          if not $scope.searchParams.skills[0]
            c['_achievements'] = _.takeRight(c.achievements)
          for i in c['_achievements']
            i['status_date'] = $filter('naiveToLocal')(i['status_date']) if i['status_date']
        $scope.gridOptions.data = response.customers
      , (response) ->
        toastr.error response.data.message

class controlKey_values
  constructor: ($scope, $state, Control, Customer, Skill, toastr, Picks, uiGridConstants, $filter, $translate) ->

    $scope.data =
      customers: []
    $scope.key_value = ''
    $scope.key_values_picks = Picks.getPicksByName('key_values')?.pick_values

    $scope.lang = "de"

    $scope.gridOptions =
      enableSorting: true
      paginationPageSize: 10
      enablePaginationControls: false
      enableGridMenu: true
      enableFiltering: true
      exporterMenuPdf: false
      columnDefs: [
        {
          enableFiltering: false,
          name: 'First name',
          field: 'first_name',
          enableCellEdit: false,
          headerCellFilter: 'translate'
          visible: false
        },
        {
          enableFiltering: false,
          name: 'Last name',
          field: 'last_name',
          enableCellEdit: false,
          headerCellFilter: 'translate'
          visible: false
        },
        {
          enableFiltering: false,
          name: 'Name',
          field: 'full_name',
          enableCellEdit: false,
          headerCellFilter: 'translate'
        },
        {
          enableFiltering: false,
          name: 'Key',
          field: 'key_value.key',
          enableCellEdit: false,
          headerCellFilter: 'translate'
          visible: true
        },
        {
          enableFiltering: false,
          name: 'Key value',
          field: 'key_value.value',
          enableCellEdit: false,
          headerCellFilter: 'translate'
          visible: true
        },
        {
          enableFiltering: false,
          'name': '#',
          'field': '_id',
          'sortable': false,
          'cellTemplate': '<a data-ui-sref="main.customer.edit.dashboard({customerId: COL_FIELD })">
                            <i class="fa fa-edit fa-2x"></i></a>',
          'enableCellEdit': false,
          'width': '50'

        }

      ]
    $scope.gridOptions.onRegisterApi = (gridApi) ->
      $scope.gridApi2 = gridApi

    $scope.startSearch = ->
      $scope.searchPromise = Customer.customerKeyValueService.get($scope.key_value)
      $scope.searchPromise.then (response) ->
        for c in response.customers
          c['full_name'] = $filter('fullName')(c)
        $scope.gridOptions.data = response.customers
        toastr.success $translate.instant('Search completed.')
      , (response) ->
        toastr.error response.data.message

class controlBooking
  constructor: ($state, $scope, Control, toastr, $translate, $filter) ->

    $scope.datepickerOptions =
      locale:
        format: 'DD.MM.YYYY'
    $scope.daterange =
      startDate: moment().subtract(1,'months').startOf('month')
      endDate: moment().subtract(1,'months').endOf('month')

    $scope.search = ->
      data =
        start_date: $scope.daterange.startDate
        end_date: $scope.daterange.endDate
      toastr.info("Please be patient. This may take a while.", "Searching...")
      $scope.searchPromise = Control.bookingControlService.post(data)
      $scope.searchPromise.then (response) ->
        toastr.success("Bookings found", "Search successful.")
        $scope.data = response
      , (response) ->
        toastr.error(response.error)
        $scope.errors = response.data.errors

    $scope.getBookingAge = (bookingDate) ->
      if bookingDate then moment(new Date(bookingDate)).fromNow() else moment().fromNow()



angular.module('sc.control', new scControl())
.controller('controlController', ['$state', '$scope', control])
.controller('controlListsController', ['$scope', '$state', 'Control', 'Skill', 'toastr', 'uiGridConstants', '$filter', controlLists])
.controller('controlKey_valuesController', ['$scope', '$state', 'Control', 'Customer', 'Skill', 'toastr', 'Picks', 'uiGridConstants', '$filter', '$translate', controlKey_values])
.controller('controlBookingController', ['$state', '$scope', 'Control', 'toastr', '$translate', '$filter', controlBooking])

angular.module('sc.allocation')
.config(['$stateProvider', allocationConfig])