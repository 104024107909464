class skillControl
  constructor: ->
    return [
      #external directives
      'ui.router'
      'restangular'
      'xeditable'
      'ui.bootstrap'
      'ngTagsInput'
      'toastr'
      'ngAnimate'
      'angular-clipboard'
      'pascalprecht.translate'
      'summernote'
      'ui.sortable'
      'ui.gravatar'
      'ui.date'
      'daterangepicker'
      'internationalPhoneNumber'
      'ui.bootstrap.datetimepicker'
      'ui.select'
      'ui.calendar'
      'ngResource'
      'uz.mailto'
      'uiGmapgoogle-maps'
      'ui.grid'
      'ui.grid.edit'
      'ui.grid.pagination'
      'ui.grid.exporter'
      'ui.grid.resizeColumns'
      'ui.grid.autoResize'
      'angular.filter'
      'ngCsv'
      'nouislider'
      'vs-repeat'

      #modules
      'sc.templates'
      'sc.factory'
      'sc.filter'
      'sc.directive'
      'sc.auth'
      'sc.dashboard'
      'sc.search'
      'sc.customer'
      'sc.company'
      'sc.location'
      'sc.stage'
      'sc.allocation'
      'sc.interaction'
      'sc.skill'
      'sc.control'
      'sc.tree'
      'sc.charts'
      'sc.admin'
    ]

class skillConfig
  constructor: ($urlRouterProvider, toastrConfig, $stateProvider, $compileProvider, gravatarServiceProvider,
    uiSelectConfig, RestangularProvider) ->

    #Our backend id is _id
    RestangularProvider.setRestangularFields({id: "_id"})

    $compileProvider.debugInfoEnabled(true)
    $urlRouterProvider.otherwise('/login')

    $stateProvider
    .state 'main',
      abstract: true,
      templateUrl: 'views/main.tpl.html',
      controller: 'skillController'

    # Customize toastr
    angular.extend toastrConfig,

        allowHtml: true,
        closeButton: false,
        progressBar: true,
        closeHtml: '<button>&times;</button>',
        containerId: 'toast-container',
        extendedTimeOut: 5000,
        iconClasses: {
          error: 'toast-error',
          info: 'toast-info',
          success: 'toast-success',
          warning: 'toast-warning'
        },
        maxOpened: 0,
        messageClass: 'toast-message',
        newestOnTop: true,
        onHidden: null,
        onShown: null,
        positionClass: 'toast-bottom-right',
        tapToDismiss: true,
        timeOut: 5000,
        titleClass: 'toast-title',
        toastClass: 'toast'

    uiSelectConfig.theme = 'bootstrap'
    # defaults for gravatar directive
    gravatarServiceProvider.defaults = {
      size: 100,
      'default': 'retro'
      # X rating to show hardcore sexual imagery or extremely disturbing violence or ME
      'rating': 'x'
    }

class checkAuth
  constructor: ($rootScope, Auth, $state, toastr, editableOptions, $translate, Restangular) ->

    # Check for ETag Errors - HttpStatusCode 412 - precondition failed
    Restangular.setErrorInterceptor (response, deferred, responseHandler) ->
      if response.status == 412
        $translate(response.data.message).then (text) ->
          toastr.error text
        return false
      if response.status == 500
        $translate('ErrorMsg').then (text) ->
          toastr.error text
        return false
      return true

    #Example for converting Timestamp to DateObjects
    regexIso8601 = "(\\d{4}-[01]\\d-[0-3]\\dT[0-2]\\d:[0-5]\\d:[0-5]\\d\.\\d+([+-][0-2]\\d:[0-5]\\d|Z))|" +
                   "(\\d{4}-[01]\\d-[0-3]\\dT[0-2]\\d:[0-5]\\d:[0-5]\\d([+-][0-2]\\d:[0-5]\\d|Z))|" +
                   "(\\d{4}-[01]\\d-[0-3]\\dT[0-2]\\d:[0-5]\\d([+-][0-2]\\d:[0-5]\\d|Z))"
    regexIso8601 = new RegExp(regexIso8601)

    convertDateStringsToDates = (input) ->
      # Ignore things that aren't objects.
      if typeof input != 'object'
        return input
      for key of input
        if !input.hasOwnProperty(key)
          continue
        value = input[key]
        match = undefined
        # Check for string properties which look like dates.
        if typeof value == 'string' and (match = value.match(regexIso8601))
          milliseconds = Date.parse(match[0])
          if !isNaN(milliseconds)
            input[key] = moment.utc(new Date(milliseconds)).local().toDate()
        else if typeof value == 'object'
          # Recurse into object
          convertDateStringsToDates value
      return input

    Restangular.addResponseInterceptor (data, operation, what, url, response, deferred) ->
      return convertDateStringsToDates(data)

    $rootScope.$state = $state
    statePromise = Auth.one('state').get()
    statePromise.then (authState) ->
      $rootScope.setState(authState)
      if not authState.user
        $state.go 'login'

    $rootScope.setState = (authState)->
      $rootScope.state = authState

    $rootScope.logout = ->
      logoutPromise = Auth.one('logout').get()
      logoutPromise.then (state) ->
        toastr.error 'Have a nice day!', 'You are logged out.'
        $rootScope.setState state
        $state.go 'login'

    editableOptions.theme = 'bs3'

class skill
  constructor: ($state, $scope, $rootScope, $location, Auth, toastr, $translate) ->

    $scope.changeTranslation = (key) ->
      $translate.use(key)

    # Filters for global search
    $scope.filters = [
      'User'
      'Customer'
      'Company'
      'Location'
      'Draft'
      'Picks'
      'Course'
      'Module'
      'Sequence'
      'Agenda'
      'Lesson'
      'Region'
      'Measure'
      'Gig'
      'Deal'
      'Booking'
      'Mandate'
      'Grant'
      'Template'
      'Term'
      'RevenueAccount'
      'CostUnit'
      'PointType'
      'Skill'
      'Crowd'
    ]

    # Start with no filter active
    $scope.activeFilter = ''

    # Change active filter
    $scope.changeFilter = (filter) ->
      $scope.activeFilter = filter

    # Clear active filter an show all data
    $scope.clearFilter = ->
      $scope.activeFilter = ''

    $scope.$on 'mapInitialized', (event, map) ->
      return


class smallHeader
  constructor: ->
    return {
    restrict: 'A'
    scope: true,
    controller: ($scope, $element) ->
      $scope.small = ->
        icon = $element.find('i:first')
        breadcrumb = $element.find('#hbreadcrumb')
        $element.toggleClass('small-header')
        breadcrumb.toggleClass('m-t-lg')
        icon.toggleClass('fa-arrow-up').toggleClass('fa-arrow-down')
    }

#directive that allowes you to implement staggering animations to any layout elements
class animatePanel
  constructor: ($timeout, $state) ->
    return {
    restrict: "A"
    link: (scope, element, attrs) ->

      #Set defaul values for start animation and delay
      startAnimation = 0
      delay = 0.06 # secunds
      start = Math.abs(delay) + startAnimation

      # Store current state where directive was start
      currentState = $state.current.name
      # Set default values for attrs
      attrs.effect = "zoomIn"  unless attrs.effect
      if attrs.delay
        delay = attrs.delay / 10
      else
        delay = 0.06
      unless attrs.child
        attrs.child = ".row > div"
      else
        attrs.child = "." + attrs.child

      # Get all visible element and set opactiy to 0
      panel = element.find(attrs.child)
      panel.addClass "opacity-0"
      # Count render time
      renderTime = panel.length * delay * 1000 + 700
      # Wrap to $timeout to execute after ng-repeat
      $timeout ->
        # Get all elements and add effect class
        panel = element.find(attrs.child)
        panel.addClass('animated-panel').addClass(attrs.effect)
        # Add delay for each child elements
        panel.each (i, elm) ->
          start += delay
          rounded = Math.round(start * 10) / 10
          $(elm).css "animation-delay", rounded + "s"
          # Remove opacity 0 after finish
          $(elm).removeClass "opacity-0"
        # Clear animate class after finish render
        $timeout (->
          # Check if user change state and only run renderTime on current state
          # Remove effect class - fix for any backdrop plgins (e.g. Tour)
          $("animated-panel:not([ng-repeat]").removeClass attrs.effect  if currentState is $state.current.name
        ), renderTime
    }

# sideNavigation - Directive for run metsiMenu on sidebar navigation
class sideNavigation
  constructor: ($timeout) ->
    return {
    restrict: 'A'
    link: (scope, element) ->
      # Call the metsiMenu plugin and plug it to sidebar navigation
      element.metisMenu()
      # Colapse menu in mobile mode after click on element
      menuElement = $('#side-menu a:not([href$="\\#"])')
      menuElement.click ->
        if ($(window).width() < 769)
          $('body').toggleClass('show-sidebar')
    }

# minimalizaSidebar - Directive for minimalize sidebar
class minimalizaMenu
  constructor: ($rootScope) ->
    return {
    restrict: 'EA'
    template: '<div class="header-link hide-menu" ng-click="minimalize()"><i class="fa fa-bars"></i></div>'
    controller: ($scope, $element) ->
      $scope.minimalize = ->
        if $(window).width() < 769
          $('body').toggleClass 'show-sidebar'
        else
          $('body').toggleClass 'hide-sidebar'
        return
      return
    }

class pageTitle
  constructor: ($rootScope, $timeout) ->
    return {
    link: (scope, element) ->
      listener = (event, toState, toParams, fromState, fromParams) ->
        # Default title
        title = 'Skill Control'
        # Create your own title pattern
        if toState.data and toState.data.pageTitle
          title = toState.data.pageTitle
        $timeout ->
          element.text title
          return
        return

      $rootScope.$on '$stateChangeStart', listener
      return
    }

# panelTools - Directive for panel tools elements in right corner of panel
class panelTools
  constructor: ($timeout) ->
    return {
      restrict: 'E'
      templateUrl: 'views/common/panel_tools.tpl.html'

      controller: ($scope, $element) ->

    }

# replace for the panelTools functionality - Use the entire panel-heading to click
class panelHeading
  constructor: ($timeout) ->
    return {
      restrict: 'CE'
      scope: {
        model: '='
        trash: '='
        length: '='
        action: '&'
        enableDelete: '@'
        hide: '@'
        enableEdit: '@'
        edit:'&'
    }

    controller: ($scope, $element) ->

      $scope.enableEdit = false
      $scope.enableDelete = false
      isHidden = false

      $scope.showhide = ->
        isHidden != isHidden
        hpanel = $element.closest('div.hpanel')
        icon = $element.find('hide:first')
        body = hpanel.find('div.panel-body')
        footer = hpanel.find('div.panel-footer')
        body.slideToggle 300
        footer.slideToggle 200
        # Toggle icon from up to down
        icon.removeClass()
        if(isHidden)
          icon.addClass('icon-down')
        else
          icon.addClass('icon-up')
        hpanel.toggleClass('').toggleClass 'panel-collapse'
        $timeout (->
          hpanel.resize()
          hpanel.find('[id^=map-]').resize()
          return
        ), 50

      if $scope.hide then $scope.showhide()

      # delete object from scope and call controller function
      $scope.delete = ->
        $scope.model.splice($scope.model.indexOf($scope.trash), 1)
        $scope.action($scope.trash)

      $element.on 'click', ->
        $scope.showhide()
    }


class tel
  constructor: ->
    return (phoneNumber) ->
      if !phoneNumber
        return phoneNumber
      formatInternational 'DE', phoneNumber

class sanitize
  constructor: ($sce) ->
    return (htmlCode) ->
      $sce.trustAsHtml(htmlCode)


angular.module('SkillControl', new skillControl())
.config(['$urlRouterProvider', 'toastrConfig', '$stateProvider', '$compileProvider', 'gravatarServiceProvider', 'uiSelectConfig', 'RestangularProvider', skillConfig])
.run(['$rootScope', 'Auth', '$state', 'toastr', 'editableOptions', '$translate', 'Restangular', checkAuth])
.controller('skillController', ['$state', '$scope', '$rootScope', '$location', 'Auth', 'toastr', '$translate', skill])
.directive('smallHeader', [smallHeader])
.directive('animatePanel', ['$timeout', '$state', animatePanel])
.directive('sideNavigation', ['$timeout', sideNavigation])
.directive('minimalizaMenu', ['$rootScope', minimalizaMenu])
.directive('pageTitle', ['$rootScope', '$timeout', pageTitle])
.directive('panelTools', ['$timeout', panelTools])
.directive('panelHeading', ['$timeout', panelHeading])
.filter('tel', [tel])
.filter('sanitize', ['$sce', sanitize])