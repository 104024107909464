class scCharts
  constructor: () ->
    return []

class scChart
  constructor: ($filter) ->
    return {
      restrict: 'A'
      scope:
        type: '@'
        data: '='
        options: '='
      link: (scope, elem) ->
        ctx = elem[0].getContext("2d")
        scope.$watchCollection 'data', (value) ->

          if ctx && scope.data.datasets.length > 0
            for dataset in scope.data.datasets
              dataset['label'] = $filter('translate')(dataset['label'])

            data = {
              labels: scope.data.labels
              datasets: scope.data.datasets
            }

            chart = new Chart(ctx, {
              type: scope.type
              data: data
              options: scope.options
            })
    }

angular.module('sc.charts', new scCharts())
.directive('scChart', ['$filter', scChart])