class adminCostUnit
  constructor: ->
    return []

class costUnitConfig
  constructor: ($stateProvider, $provide) ->
    $stateProvider
    .state 'main.admin.costUnit',
      url: '/cost_unit'
      abstract: true
      template: "<div ui-view=''></div>"

    .state 'main.admin.costUnit.create',
      url: "/create"
      templateUrl: "admin/cost_unit/cost_unit.tpl.html",
      controller: "adminCostUnitCreateController",
      data:
        pageTitle: 'Create a cost unit',
        pageName: 'View cost unit'

    .state 'main.admin.costUnit.edit',
      url: "/edit/:costUnitId",
      templateUrl: "admin/cost_unit/cost_unit.tpl.html",
      controller: "adminCostUnitEditController",
      data:
        pageTitle: 'Edit a cost unit',
        pageName: 'View cost unit'

class adminCostUnitCreate
  constructor: ($scope, $state, CostUnit, toastr) ->
    $scope.costUnit = {
      name: ""
      unit_number: ""
    }

    $scope.saveCostUnit = ->
      costUnitPromise = CostUnit.post($scope.costUnit)
      costUnitPromise.then (response) ->
        $state.go 'main.admin.costUnit.edit', {'costUnitId': response._id}
      , (response) ->
        $scope.errors = response.data.errors
        toastr.error(response.error)


class adminCostUnitEdit
  constructor: ($scope, $state, $stateParams, CostUnit, toastr) ->
    $scope.model = []
    $scope.data = {}
    $scope.costUnit = {
      name: ""
      unit_number: ""
    }

    if $stateParams.costUnitId?.length
      costUnitPromise = CostUnit.one($stateParams.costUnitId).get()
      costUnitPromise.then (response) ->
        $scope.costUnit = response

    $scope.clearCostUnit = ->
      $scope.costUnit =
        name: ""
        unit_number: ""
      toastr.success("Cost unit wiped.", "Wipe successful.")

    $scope.cloneCostUnit = ->
      $scope.costUnit._id = ''
      $scope.costUnit.name = $scope.costUnit.name + " - Clone"
      toastr.success("Cost unit " + $scope.costUnit.name + " was born.", "Clone successful.")
      $scope.saveCostUnit()

    $scope.saveCostUnit = ->
      if $scope.costUnit._id
        CostUnit.one($scope.costUnit._id)
        .customPUT($scope.costUnit)
        .then (data) ->
          $scope.errors = []
          toastr.success(data.name + " saved.", "Edit successful.")
        , (response) ->
          $scope.errors = response.data.errors
          toastr.error(response.error)
      else
        CostUnit.post($scope.costUnit)
        .then (data) ->
          $scope.errors = []
          $scope.costUnit = data
          toastr.success(data.name + " saved.")
          $state.go 'main.admin.costUnit.edit', {'costUnitId': data._id}
        , (response) ->
          $scope.errors = response.data.errors
          toastr.error(response.error)

    $scope.deleteCostUnit = ->
      CostUnit.one($scope.costUnit._id)
      .customDELETE()
      .then (data) ->
        toastr.success(data.name + " was eliminated.", "Fare thee well good old cost unit.")
        $scope.clearCostUnit()
      , (response) ->
        $scope.errors = response.data.errors
        toastr.error(response.error)

angular.module('admin.costUnit', new adminCostUnit())
.config(['$stateProvider', '$provide', costUnitConfig])
.controller('adminCostUnitCreateController', ['$scope', '$state', 'CostUnit', 'toastr', adminCostUnitCreate])
.controller('adminCostUnitEditController', ['$scope', '$state', '$stateParams', 'CostUnit', 'toastr', adminCostUnitEdit])