#Defines sc models
#type of model key
#Low name to show in toastr header - if value of model key is nested
#Deep name to show in toastr description if value model key is more nested
models =
  deal:
    invoices:
      type: 'ObjectArray'
      low_name: 'invoice_number'
      deep_name: 'debit_number'
  customer:
    comments:
      type: 'ObjectArray'
      low_name: 'title'
    achievements:
      type: 'ObjectArray'
      low_name: 'name'
    credentials:
      type: 'ObjectKey'
    source:
      type: 'ObjectKey'
    ad_stop:
      type: 'ObjectKey'
    position:
      type: 'ObjectKey'
    alert:
      type: 'ObjectKey'
    zone:
      type: 'ObjectKey'
    tags:
      type: 'ObjectKey'
    phones:
      type: 'ObjectArray'
      low_name: 'number'

#Factory to generate better error messages for sc users
class Error
  constructor: ($filter, toastr) ->
    return {
      getErrorMessage: (errors, model, model_name) ->
        #Get all errors
        for _error in errors
          #Split error string
          _errorArray = _error.name.split(".")
          #Index 0 of _errorArray is model key
          model_key = models[model_name][_errorArray[0]]
          #Check type of model key
          if model_key.type == 'ObjectArray'
            #Get key name for toastr header
            key_name = model[_errorArray[0]][_errorArray[1]][model_key.low_name]
            #Check if key is nested
            if _errorArray.length < 4
              message = new MessageGenerator(
                $filter,
                _error.description,
                key_name,
                {value: _errorArray[2]},
                _errorArray[0]
              )
            if  _errorArray.length > 4
              #Get nested key name for better toastr description
              deep_key_name = model[_errorArray[0]][_errorArray[1]][_errorArray[2]][_errorArray[3]][model_key.deep_name]
              message = new MessageGenerator(
                $filter,
                _error.description,
                key_name,
                {value: _errorArray[4], key: deep_key_name},
                _errorArray[0]
              )
          if model_key.type == 'ObjectKey'
            message = new MessageGenerator(
              $filter,
              _error.description,
              '',
              {value: _error.name},
              ''
            )
          toastr.error message.body, message.header
    }

# Generate message for angular-toastr
# First parameter is $filter for translation and needs to be passed from Error Factory
class MessageGenerator
  constructor: (angular_filter, description, key_name, deep_item, location) ->

    translated_key = angular_filter('translate')(deep_item.value)
    translated_description = angular_filter('translate')(description)
    translated_location = angular_filter('translate')(location)

    @header = if key_name then "#{key_name}" else translated_location
    @body = "#{translated_key} #{translated_description}"

    if deep_item.key
      @body = "#{deep_item.key} - #{translated_key} #{translated_description}"

    MessageGenerator





angular.module('sc.factory')
.factory('Error', ['$filter', 'toastr', Error])