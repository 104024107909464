class allocationBooking
  constructor: ->
    return []

class bookingConfig
  constructor: ($stateProvider, $provide) ->
    $stateProvider
    .state 'main.allocation.booking',
      url: '/booking'
      abstract: true
      template: "<div ui-view=''></div>"

    .state 'main.allocation.booking.create',
      url: "/create",
      templateUrl: "allocation/booking/booking.tpl.html",
      controller: "allocationBookingCreateController",
      data:
        pageTitle: 'Create a booking',
        pageName: 'View booking'

    .state 'main.allocation.booking.edit',
      url: "/edit/:bookingId",
      templateUrl: "allocation/booking/booking.tpl.html",
      controller: "allocationBookingEditController",
      data:
        pageTitle: 'Edit a booking',
        pageName: 'View booking'

class allocationBookingCreate
  constructor: ($scope, Booking, toastr, $state, $translate) ->
    $scope.data = {
      bookingTypes: Booking.bookingTypes
      statusTypes: Booking.statusTypes
      contactFilter: [
        {name: "Customer", fields: ["first_name", "last_name", "number"]}
        {name: "Company", fields: ["name", "number"]}
      ]
    }

    $scope.booking =
      supervisors: []
      contacts: []
      gigs: []
      booking_type: Booking.bookingTypes[0]
      status: Booking.statusTypes[0]
      tags: null

    $scope.createBooking = ->
      Booking.service.post($scope.booking)
      .then (response) ->
        $translate('CreateMsg', {name: response.number}).then (text) ->
          toastr.success text
        $state.go 'main.allocation.booking.edit', {'bookingId': response._id}
      , (response) ->
        console.log(response)
        $scope.errors = response.data.errors


class allocationBookingEdit
  constructor: ($scope, $state, $rootScope, $stateParams, Booking, toastr, $translate, $resource) ->
    $scope.model = []
    $scope.data = {
      bookingTypes: Booking.bookingTypes
      statusTypes: Booking.statusTypes
      partyTypes: Booking.partyTypes
      contactFilter: [
        {name: "Customer", fields: ["first_name", "last_name", "number"]}
        {name: "Company", fields: ["name", "number"]}
      ]
    }
    $scope.booking =
      supervisors: []
      contacts: []
      parties: []
      gigs: []
      costs: []

    $scope.getTotals = (costs) ->
      sum = 0.0
      for cost in costs
        sum += cost.amount * cost.quantity
      return sum

    $scope.newCost = {
      tax_free: false
    }

    $scope.toggle = (scope) ->
      scope.toggle()

    $scope.selectBooking = (booking) ->
      $scope.booking = booking
      return

    $scope.clearBooking = ->
      toastr.success("Booking wiped.", "Wipe successful.")
      $state.go 'main.allocation.booking.create'

    if $stateParams.bookingId?.length
      bookingPromise = Booking.service.one($stateParams.bookingId).get()
      bookingPromise.then (response) ->
        $scope.booking = response

    # Approve a booking
    $scope.approve = ->
      $scope.booking.approval =
        user_id: $rootScope.state.user._id
        full_name: $rootScope.state.user.full_name
        approval_date: new Date()
      $scope.saveBooking()

    # prepare user data and save booking
    $scope.addSupervisor = (user) ->
      user.user_id = user._id
      user.topic = $scope.data.supervisorTopic
      $scope.booking.supervisors.push(user)
      $scope.saveBooking()

    # set used filter
    $scope.selectContact = (model, filter) ->
      $scope.data.activeFilter = filter


    # prepare contact data and save booking
    $scope.addContact = (contact, filter)->
      contact.contact_id = contact._id
      contact.topic = $scope.data.contactTopic
      contact.contact_type = $scope.data.activeFilter
      $scope.booking.contacts.push(contact)
      $scope.saveBooking()

    $scope.addGig = (gig) ->
      gig.gig_id = gig._id
      $scope.booking.gigs.push(gig)
      $scope.clear = !$scope.clear
      $scope.saveBooking()


    $scope.cloneBooking = ->
      $scope.booking._id = ''
      $scope.booking.number = $scope.booking.number + " - Clone"
      toastr.success("Booking" + $scope.booking.number + " was born.", "Clone successful.")
      $scope.saveBooking()


    booking_document = $resource('booking/:booking_id/document', {booking_id: $stateParams.bookingId},
      pdf:
        method: 'GET'
        header:
          accept: 'application/pdf'
        responseType: 'arraybuffer'
        cache: false
        transformResponse: (data, header) ->
          decoder = new TextDecoder()
          msg = decoder.decode(data)
          pdf = undefined
          if data
            pdf = new Blob([data], {type: 'application/pdf'})
          return {
            response: pdf
            text: msg
          }
    )

    $scope.document = (name) ->
      booking_document.pdf().$promise
      .then (data) ->
        blob = data.response
        saveAs(blob, name + '.pdf')
      , (response) ->
        console.log(error)
        $translate("ErrorMsg").then (text) ->
          toastr.error error.data.text

    #Push single cost into $scope.data.costs
    $scope.addCost = ->
      $scope.booking.costs.push($scope.newCost)
      $scope.saveBooking()

    $scope.selectCostUnit = (model) ->
      $scope.newCost.cost_unit =
        'name': model.name
        'unit_number': model.unit_number
      model = undefined

    $scope.saveBooking = ->
      $scope.booking.tags = _.map($scope.booking.tags, 'text')
      if $scope.booking._id
        Booking.service.one($scope.booking._id)
        .customPUT($scope.booking)
        .then (data) ->
          $scope.booking = data
          $scope.errors = []
          toastr.success(data.number + " saved.", "Edit successful.")
        , (response) ->
          toastr.error(response.error)
          $scope.errors = response.data.errors
      else
        Booking.service.post($scope.booking)
        .then (data) ->
          $scope.booking = data
          $scope.errors = []
          toastr.success(data.number + " saved.", "Save successful.")
        , (response) ->
          toastr.error(response.error)
          $scope.errors = response.data.errors

    $scope.deleteBooking = ->
      Booking.service.one($scope.booking._id)
      .customDELETE()
      .then (data) ->
        $scope.booking = data
        $scope.errors = []
        toastr.success(data.number + " was eliminated.", "Good bye my fellow booking.")
      , (response) ->
        toastr.error(response.error)
        $scope.errors = response.data.errors

    #Booking actions
    $scope.execute = (action) ->
      payload = {'action': action}
      Booking.service.one($scope.booking._id).one('action').customPOST(payload).then (response) ->
        toastr.success 'Action successfully executed'
      , (response) ->
        console.log(response)
        toastr.error(response.data.message)


angular.module('allocation.booking', new allocationBooking())
.config(['$stateProvider', '$provide', bookingConfig])
.controller('allocationBookingCreateController', ['$scope', 'Booking', 'toastr', '$state', '$translate', allocationBookingCreate])
.controller('allocationBookingEditController', ['$scope', '$state', '$rootScope', '$stateParams', 'Booking', 'toastr', '$translate', '$resource', allocationBookingEdit])