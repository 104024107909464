class scFactory
  constructor: ->
    return ['btford.socket-io']

class idCollector
  constructor: ->
    return {
      getCollection: (_id, data, item) ->
        collector = []
        n = 0
        while n < data.length
          i = 0
        while i < data[n][item].length
          element =
            customer_id: data[n][item][i].customer_id
            company_id: data[n]._id
            company_name: data[n].name
            topic: data[n][item][i].topic
            type: data[n][item][i].type
          if _id == element.customer_id
            collector.push element
          i++
        n++
        collector
    }

class Config
  constructor: (Restangular) ->
    return Restangular.service 'assets/config.json'

class Generator
  constructor: ->
    chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghiklmnopqrstuvwxyz'
    reg = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/
    passwordArray = []

    newPaswword = (len) ->
      passwordArray = (chars.substr(Math.floor(Math.random() * chars.length), 1) for i in [1..len])

    return {
      generatePassword: (len) ->
        len ?= 8
        passwordArray = []
        newPaswword(len) while not reg.test(passwordArray.join '')
        #console.log(passwordArray.join '')
        passwordArray.join ''
    }

class Auth
  constructor: (Restangular) ->
    return Restangular.service 'auth'

class ApiAuth
  constructor: (Restangular) ->
    return Restangular.service 'api/auth'


class Crowd
  constructor: (Restangular) ->
    return Restangular.service 'crowd'

class Control
  constructor: (Restangular) ->
    return {
      hmdService: Restangular.service 'hmd'
      customerFilterService: Restangular.service 'customer_filter'
      customerTagService: Restangular.service 'customer_tag'
      gigFilterService: Restangular.service 'gig_filter'
      gigLessonService: Restangular.service 'gig_lesson'
      customerService: Restangular.service 'customer_discover'
      debitControlService: Restangular.service 'debit_control'
      invoiceControlService: Restangular.service 'invoice_control'
      dealControlService: Restangular.service 'deal_control'
      measureDealControlService: Restangular.service 'measure_deals'
      turnoverService: Restangular.service 'stats/turnover'
      hopesControlService: Restangular.service 'hopes_control'
      workloadService: Restangular.service 'stats/workload'
      bookingControlService: Restangular.service 'booking_control'
      gigsControlService: Restangular.service 'gig_control'
      middlemanControlService: Restangular.service 'middleman_control'
      companyTagService: Restangular.service 'control/company/tag'
      companyControlService: Restangular.service 'company_control'
    }

class Interaction
  constructor: (Restangular) ->
    return {
      invoiceService: Restangular.service 'invoices'
    }

class Task
  constructor: ->
    return {
      collectionTypes: [
        'Customer'
        'Company'
        'Draft'
        'Measure'
        'Gig'
        'Deal'
      ]
      departmentTypes: [
        'production'
        'service'
        'operations'
        'accounting'
        'command'
        'development'
      ]
      urgencyTypes: [
        'low'
        'medium'
        'high'
      ]
      impactTypes: [
        'low'
        'medium'
        'high'
      ]
      priorityTypes: [
        'critical'
        'high'
        'medium'
        'low'
        'very low'
      ]
    }

class Stats
  constructor: (Restangular) ->
    return {
      generalService: Restangular.service 'stats/general'
      highScoreService: Restangular.service 'stats/high_score'
    }


class Admin
  constructor: (Restangular) ->
    return Restangular.service 'admin'

class User
  constructor: (Restangular) ->
    return Restangular.service 'user'

class Draft
  constructor: (Restangular) ->
    return {
      service: Restangular.service 'draft'
      statusTypes: [
        'projected'
        'approved'
        'completed'
      ]
    }

class Course
  constructor: (Restangular) ->
    return Restangular.service 'course'

class Module
  constructor: (Restangular) ->
    return Restangular.service 'module'

class Gig
  constructor: (Restangular) ->
    return {
      service: Restangular.service 'gig'
      importService: Restangular.service 'gigs'
      attendeeStatus: [
        'assigned'
        'warranted'
        'allowed'
        'cat-state'
        'absent'
        'present'
      ]
    }

class Match
  constructor: (Restangular) ->
    return {
      gigMatchService: Restangular.service 'matching/gig'
    }


class Term
  constructor: (Restangular) ->
    return Restangular.service 'term'

class Deal
  constructor: (Restangular) ->
    return {
      service: Restangular.service 'deal'
      statusTypes: [
        'virgin'
        'submitted'
        'offered'
        'rejected'
        'signed'
        'canceled'
        'nullified'
        'obsolete'
        'fulfilled'
      ]
      invoiceStatusTypes: [
        'provisional',
        'invoiced',
        'cancelled-provisional',
        'cancelled-invoiced'
      ]
      debitStatusTypes: [
        'provisional',
        'debited'
      ]
      partyTypes: [
        'seller'
        'buyer'
        'middleman'
        'deal_recipient'
        'service_recipient'
        'invoice_recipient'
        'invoice_issuer'
        'declaration_recipient'
      ]
    }

class Mandate
  constructor: (Restangular) ->
    return {
      service: Restangular.service 'mandate'
      statusTypes: [
        'virgin'
        'offered'
        'rejected'
        'signed'
        'canceled'
        'nullified'
        'obsolete'
        'expired'
      ]
      partyTypes: [
        'debitor'
        'depositor'
        'creditor'
      ]
    }

class Grant
  constructor: (Restangular) ->
    return {
      service: Restangular.service 'grant'
      statusTypes: [
        'virgin'
        'initiated'
        'earmarked'
        'offered'
        'rejected'
        'signed'
        'canceled'
        'nullified'
        'obsolete'
        'fulfilled'
      ]
      partyTypes: [
        'donor'
        'grantee'
      ]
    }

class Template
  constructor: (Restangular) ->
    return Restangular.service 'template'

class Booking
  constructor: (Restangular) ->
    return {
      service: Restangular.service 'booking'
      certificateService: Restangular.service 'zero_tax_certificate'

      statusTypes: [
        'virgin'
        'initiated'
        'earmarked'
        'offered'
        'rejected'
        'signed'
        'canceled'
        'nullified'
        'obsolete'
        'fulfilled'
      ]
      bookingTypes: [
        'hotel'
        'trainer'
        'flight'
        'car'
        'train'
        'location'
        'author'
        'accommodation'
        'reimbursement'
      ]
      partyTypes: [
        'contractor'
        'assignee'
        'purchaser'
      ]
    }

class Measure
  constructor: (Restangular) ->
    return {
      service: Restangular.service 'measure'
      measure_list: Restangular.service 'measure_list'
      measure_module: Restangular.service 'measure_module'
      statusTypes: [
        'projected'
        'approved'
        'launched'
        'suspended'
        'canceled'
        'nullified'
        'rejected'
        'completed'
      ]
    }

class Region
  constructor: (Restangular) ->
    return Restangular.service 'region'

class Sequence
  constructor: (Restangular) ->
    return Restangular.service 'sequence'

class Agenda
  constructor: (Restangular) ->
    return Restangular.service 'agenda'

class Lesson
  constructor: (Restangular) ->
    return Restangular.service 'lesson'

class Skill
  constructor: (Restangular) ->
    return {
      service: Restangular.service 'skill'
      renewalPeriodTypes: [
        'days'
        'weeks'
        'months'
        'years'
      ]
      latecomerModes: [
        'merciless'
        'linear'
      ]
      durationPeriodTypes: [
        'days',
        'weeks'
        'months'
        'years'
      ]
    }

class PointType
  constructor: (Restangular) ->
    return Restangular.service 'point_type'

class Layout
  constructor: (Restangular) ->
    return Restangular.service 'layout'

class RevenueAccount
  constructor: (Restangular) ->
    return Restangular.service 'revenue_account'

class CostUnit
  constructor: (Restangular) ->
    return Restangular.service 'cost_unit'

class Content
  constructor: (Restangular) ->
    return {
      service: Restangular.service 'content'
      contentTypes: ['course', 'module']
    }

# Customer
class Customer
  constructor: (Restangular) ->
    return {
      service: Restangular.service 'customer'
      customerKeyValueService: Restangular.service 'customer/key'
      measureService: Restangular.service 'customer_gig_measures'
      titles: ['', 'Dr.', 'Prof.', 'Prof. Dr.', 'Graf', 'Freiherr', 'Baron', 'Herzog', 'Dipl.-Ing.',
        'Dipl.-Kfm.', 'Rechtsanwalt', 'Diplom-Betriebswirt', 'Vorstandsvorsitzender',
        'Geschäftsführer', 'Gräfin', 'Freiin', 'Freifrau', 'Baronin', 'Herzogin',
        'Dipl.-Kff.', 'Rechtsanwältin', 'Diplom-Betriebswirtin',
        'Vorstandsvorsitzende', 'Geschäftsführerin']
      phoneTypes: ['home', 'mobile', 'office', 'fax']
      addressTypes: ['home', 'shipping', 'billing', 'office', 'birthplace']
      mailTypes: ['personal', 'business']
      credentialTypes: ['ILIAS', 'website']
      achievementStatus: [
        'requested'
        'refused'
        'started'
        'active'
        'cat-state'
        'achieved'
        'aborted'
      ]
      sources: ['Fair', 'Email', 'Personal contact', 'Phone', 'Fax', 'Contact form',
        'Online survey', 'Survey', 'Customer import',
        'Letter', 'Employment agency', 'Federal Armed Forces', 'License', 'Homepage', 'Mailing', 'Company',
        'Advertising']
      summernoteOptions: {
        height: 300,
        toolbar: [
          ['edit', ['undo', 'redo']],
          ['headline', ['style']],
          ['style', ['bold', 'italic', 'underline', 'superscript', 'subscript', 'strikethrough', 'clear']],
          ['fontface', ['fontname']],
          ['textsize', ['fontsize']],
          ['fontclr', ['color']],
          ['alignment', ['ul', 'ol', 'paragraph', 'lineheight']],
          ['height', ['height']],
          ['table', ['table']],
          ['insert', ['hr', 'link']],
          ['view', ['fullscreen']],
          ['help', ['help']]
        ]
      }

# Prepare data for service call
      customerData: (scopeData) ->
        data =
          sex: scopeData.sex
          title: scopeData.title
          first_name: scopeData.first_name
          last_name: scopeData.last_name
          birthday: scopeData.birthday
          alert: scopeData.alert
          position: scopeData.position
          number: scopeData.number
          busy: scopeData.busy
          zone: scopeData.zone
          ad_stop: scopeData.ad_stop
          source: scopeData.source
          tags: scopeData.tags
          addresses: scopeData.addresses
          default_address: scopeData.default_address
          mails: scopeData.mails
          phones: scopeData.phones
          credentials: scopeData.credentials
          key_values: scopeData.key_values
          comments: scopeData.comments
          achievements: scopeData.achievements
          changed: scopeData.changed
        data
    }

# Company
class Company
  constructor: (Restangular) ->
    return {
      service: Restangular.service 'company'
      phoneTypes: ['public', 'fax']
      addressTypes: ['registered', 'shipping', 'billing', 'office']
      mailTypes: ['public']
      locationAddressTypes: ['entrance', 'parking', 'shipping']
      hopeStatus: ['raised', 'vague', 'confident', 'justified', 'fulfilled', 'lost']

      summernoteOptions: {
        height: 300,
        toolbar: [
          ['edit', ['undo', 'redo']],
          ['headline', ['style']],
          ['style', ['bold', 'italic', 'underline', 'superscript', 'subscript', 'strikethrough', 'clear']],
          ['fontface', ['fontname']],
          ['textsize', ['fontsize']],
          ['fontclr', ['color']],
          ['alignment', ['ul', 'ol', 'paragraph', 'lineheight']],
          ['height', ['height']],
          ['table', ['table']],
          ['insert', ['hr', 'link']],
          ['view', ['fullscreen']],
          ['help', ['help']]
        ]
      }
# Prepare data for service call
      companyData: (scopeData) ->
        data =
          name: scopeData.name
          industry: scopeData.industry
          alert: scopeData.alert
          number: scopeData.number
          zone: scopeData.zone
          ad_stop: scopeData.ad_stop
          source: scopeData.source
          tags: scopeData.tags
          addresses: scopeData.addresses
          mails: scopeData.mails
          phones: scopeData.phones
          contacts: scopeData.contacts
          comments: scopeData.comments
          supervisors: scopeData.supervisors
          locations: scopeData.locations
          hopes: scopeData.hopes
          changed: scopeData.changed
        data
    }

# Location
class Location
  constructor: (Restangular) ->
    return {
      service: Restangular.service 'location'
      addressTypes: ['entrance', 'shipping', 'parking']
      summernoteOptions: {
        height: 300,
        toolbar: [
          ['edit', ['undo', 'redo']],
          ['headline', ['style']],
          ['style', ['bold', 'italic', 'underline', 'superscript', 'subscript', 'strikethrough', 'clear']],
          ['fontface', ['fontname']],
          ['textsize', ['fontsize']],
          ['fontclr', ['color']],
          ['alignment', ['ul', 'ol', 'paragraph', 'lineheight']],
          ['height', ['height']],
          ['table', ['table']],
          ['insert', ['hr', 'link']],
          ['view', ['fullscreen']],
          ['help', ['help']]
        ]
      }
    }


# class NewsListener extends Factory('sc.factory')
#   constructor: (NewsSocket, toastr) ->
#     NewsSocket.on 'news', (data) ->
#       toastr.info data.message, 'News'
#     return null

# class NewsSocket extends Factory('sc.factory')
#   constructor: ($rootScope) ->
#     socket = io.connect('/news')
#     return {
#       on: (eventName, callback) ->
#         socket.on eventName, ->
#           args = arguments
#           $rootScope.$apply ->
#             callback.apply socket, args
#       emit: (eventName, data, callback) ->
#         socket.emit eventName, data, ->
#           args = arguments
#           $rootScope.$apply ->
#             if callback
#               callback.apply socket, args
#       }


# LMS
class Lms
  constructor: (Restangular) ->
    return {
      categoryService: Restangular.service 'ilias_category'
      courseService: Restangular.service 'ilias_course'
      draftService: Restangular.service 'ilias_draft'

      availabilityTypes: [
        'temporarily available'
        'not available'
        'unlimited'
      ]

      statusTypes: [
        'online'
        'offline'
      ]

      draftStatusTypes: [
        'virgin'
        'approved'
      ]

      memberStatusTypes: [
        'allowed'
        'warranted'
        'assigned'
      ]

      chargingTypes: [
        'in advance'
        'in arrears'
      ]

      chargingPeriods: [
          'days'
          'weeks'
          'months'
          'years'
      ]

      subscriptionPeriods: [
          'days'
          'weeks'
          'months'
          'years'
      ]

      contactTypes: ['Customer', 'Company']


      pointExpirationTypes: [
        'end of year'
        'end of month'
        'end of century'
        'end of ages'
        'end of time'
      ]

      daterange: {
        startDate: new Date()
        endDate: new Date()
      }

      locale:  {
        format: 'DD.MM.YYYY'
      }

    }


class Order
  constructor: (Restangular, toastr) ->
    return {
      orderService: Restangular.service 'shop/order'
    }

class Picks
  constructor: (Restangular, toastr) ->
    picksData = null
    picksService = Restangular.service 'picks'
    picksPromise = picksService.one().get().then (response) ->
      picksData = response.picks
    , (response) ->
      toastr.error( "Error while trying to get picks","Please contact the administrator." )
      #$scope.errors = response.data.errors #scope shouldnt be referenced in a factory
    return {
      picksPromise: picksPromise
      picksService: Restangular.service 'picks'
      getPicks: ->
        return picksData
      getPicksByName: (name) ->
        return _.filter(picksData, 'name': name)[0]
      #setPicks: (name, value) ->
    }


class Points
  constructor: (Restangular) ->
    return {
      pointTypes: [
        'Logic'
        'Luck'
        'Happy'
        'Love'
        'Skill'
        'Funny'
        'Nemesis'
      ]

      pointExpirationTypes: [
        'end of year'
        'end of month'
        'end of century'
        'end of ages'
        'end of time'
      ]
    }


angular.module('sc.factory', new scFactory())
.factory('IdCollector', [idCollector])
.factory('Config', ['Restangular', Config])
.factory('Generator', [Generator])
.factory('Auth', ['Restangular', Auth])
.factory('ApiAuth', ['Restangular', ApiAuth])
.factory('Crowd', ['Restangular', Crowd])
.factory('Control', ['Restangular', Control])
.factory('Interaction', ['Restangular', Interaction])
.factory('Task', [Task])
.factory('Stats', ['Restangular', Stats])
.factory('Admin', ['Restangular', Admin])
.factory('User', ['Restangular', User])
.factory('Draft', ['Restangular', Draft])
.factory('Course', ['Restangular', Course])
.factory('Module', ['Restangular', Module])
.factory('Gig', ['Restangular', Gig])
.factory('Match', ['Restangular', Match])
.factory('Term', ['Restangular', Term])
.factory('Deal', ['Restangular', Deal])
.factory('Mandate', ['Restangular', Mandate])
.factory('Grant', ['Restangular', Grant])
.factory('Template', ['Restangular', Template])
.factory('Booking', ['Restangular', Booking])
.factory('Measure', ['Restangular', Measure])
.factory('Region', ['Restangular', Region])
.factory('Sequence', ['Restangular', Sequence])
.factory('Agenda', ['Restangular', Agenda])
.factory('Lesson', ['Restangular', Lesson])
.factory('Skill', ['Restangular', Skill])
.factory('PointType', ['Restangular', PointType])
.factory('Layout', ['Restangular', Layout])
.factory('RevenueAccount', ['Restangular', RevenueAccount])
.factory('CostUnit', ['Restangular', CostUnit])
.factory('Content', ['Restangular', Content])
.factory('Customer', ['Restangular', Customer])
.factory('Company', ['Restangular', Company])
.factory('Location', ['Restangular', Location])
.factory('Lms', ['Restangular', Lms])
.factory('Order', ['Restangular', 'toastr', Order])
.factory('Picks', ['Restangular', 'toastr', Picks])
.factory('Points', ['Restangular', Points])