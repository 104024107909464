class scSkill
  constructor: ->
    return []

class allocationConfig
  constructor: ($stateProvider) ->
    $stateProvider

    .state 'main.skill.skill',
      url: '/skill'
      abstract: true
      template: "<div ui-view=''></div>"

    .state 'main.skill.skill.create',
      url: "/create",
      templateUrl: "skill/skill/skill.tpl.html",
      controller: "skillSkillCreateController",
      data:
        pageTitle: 'Create a skill',
        pageName: 'View skill'

    .state 'main.skill.skill.edit',
      url: "/edit/:skillId",
      templateUrl: "skill/skill/skill.tpl.html",
      controller: "skillSkillEditController",
      data:
        pageTitle: 'Create a skill',
        pageName: 'View skill'
      resolve:{
        'picksData': (Picks) ->
          return Picks.picksPromise
      }

class skillSkillCreate
  constructor: ($scope, Skill, toastr, $state, $translate) ->
    $scope.data = {}
    $scope.skill =
      name: ""
      requirements: {}
      expirable: false

    $scope.saveSkill = ->
      Skill.service.post($scope.skill)
      .then (response) ->
        $translate('CreateMsg', {name: response.name}).then (text) ->
          toastr.success text
        $state.go 'main.skill.skill.edit', {'skillId': response._id}
      , (response) ->
        toastr.error(response.data.errors)
        $scope.errors = response.data.errors


class skillSkillEdit
  constructor: ($scope, $state, $stateParams, Lms, Picks, Skill, toastr) ->
    $scope.model = []
    $scope.data = {}
    $scope.trash = {}
    $scope.skill =
      requirements: {}

    emptyPointRequirement = ->
      $scope.data.pointRequirement =
        point_types: []

    emptyPointRequirement()

    $scope.renewalPeriodTypes = Skill.renewalPeriodTypes
    $scope.latecomerModes = Skill.latecomerModes
    $scope.durationPeriodTypes = Skill.durationPeriodTypes

    $scope.toggle = (scope) ->
      scope.toggle()

    $scope.selectSkill = (skill) ->
      $scope.skill = skill
      return

    $scope.clearSkill = ->
      $scope.skill =
        name: ""
        requirements: {}
      toastr.success("Skill wiped.", "Wipe successful.")

    if $stateParams.skillId?.length
      skillPromise = Skill.service.one($stateParams.skillId).get()
      skillPromise.then (response) ->
        $scope.skill = response

    $scope.addSkillRequirement = (model)->
      requirement =
        skill_id: model._id
      $scope.skill.requirements.skills.push(requirement)
      $scope.saveSkill()
      return

    $scope.selectPointType = (model) ->
      $scope.data.pointRequirement.point_types.push({
        point_type_id: model._id
        name: model.name
      })
      $scope.newPointType = ""

    $scope.addPointRequirement = (requirement)->
      $scope.skill.requirements.points.push(requirement)
      $scope.saveSkill()
      emptyPointRequirement()
      return

    $scope.cloneSkill = ->
      delete $scope.skill._id
      $scope.skill.name = $scope.skill.name + " - Clone"
#      toastr.success("Skill" + $scope.skill.name + " was born.", "Clone successful.")
      $scope.saveSkill()

    $scope.saveSkill = ->
      if $scope.skill._id
        Skill.service.one($scope.skill._id)
        .customPUT($scope.skill)
        .then (data) ->
          $scope.skill = data
          $scope.errors = []
          toastr.success(data.name + " saved.", "Edit successful.")
          $state.go 'main.skill.skill.edit', {'skillId': data._id}
        , (response) ->
          toastr.error(data.name + " not saved.", "Edit not successful.")
          for error in response.data.errors
            toastr.error(error.description + ": " + error.name, response.statusText)
      else
        Skill.post($scope.skill)
        .then (data) ->
          $scope.skill = data
          $scope.errors = []
          toastr.success(data.name + " saved.", "Save successful.")
          $state.go 'main.skill.skill.edit', {'skillId': data._id}
        , (response) ->
          toastr.error('Please check if '+ $scope.skill.name + ' still exists.')
          $state.go 'main.skill.skill.create'

    $scope.deleteSkill = ->
      Skill.service.one($scope.skill._id)
      .customDELETE()
      .then (data) ->
        $scope.skill = data
        $scope.errors = []
        toastr.success(data.name + " was eliminated.", "Good bye my fellow skill.")
        $state.go 'main.skill.skill.create'
      , (response) ->
        toastr.error(response.error)
        $scope.errors = response.data.errors

    $scope.saveTerm = ->
      $scope.skill.tags = _.map($scope.skill.tags, 'text')
      $scope.saveSkill()

    $scope.wipeTemplate = ->
      $scope.skill.bundles = []
      $scope.saveSkill()

    $scope.selectTemplate = (template) ->
      # copy defaults to term_args
      for bundle in template.bundles
        # set selection on single term bundles
        if bundle.terms.length == 1
          bundle.selection = 0
        for term in bundle.terms
          term.term_args = _.defaults(term.term_args? or {}, term.defaults)
      $scope.skill.bundles = template.bundles
      $scope.saveSkill()

    $scope.execute = (action) ->
      payload = {'action': action}
      Skill.service.one($scope.skill._id).one('action').customPOST(payload).then (response) ->
        toastr.success 'Action successfully executed'
        $state.reload()
      , (response) ->
        console.log(response)
        toastr.error(response.data.message)


angular.module('skill.skill', new scSkill())
.config(['$stateProvider', allocationConfig])
.controller('skillSkillCreateController', ['$scope', 'Skill', 'toastr', '$state', '$translate', skillSkillCreate])
.controller('skillSkillEditController', ['$scope', '$state', '$stateParams', 'Lms', 'Picks', 'Skill', 'toastr', skillSkillEdit])