userFields = ['user_name', 'rank', 'email']
customerFields = ['number', 'first_name', 'last_name', 'phones', 'mails',
                  'addresses', 'source', 'tags']
companyFields = ['number', 'name', 'source', 'tags', 'phones', 'addresses', 'mails']
dealFields = ['deal_number','tags']
grantFields = ['number']
mandateFields = ['number', 'tags']
bookingFields = ['number', 'entry_date']
lessonFields = ['content']

documents =
  User: userFields
  Customer: customerFields
  Company: companyFields
  Deal: dealFields
  Grant: grantFields
  Mandate: mandateFields
  Booking: bookingFields
  Lesson: lessonFields

class searchFilter
  constructor: ($filter, $translate) ->
    return (data, document_type) ->
      match = {}
      if documents[document_type]
        for field in documents[document_type]
          if _.isArray(data[field])
            filter = new NestedMatch(data[field], field)
            match[field] = filter.result if filter.result?.length
          else
            match[field] = data[field] if data[field]?.length
      else
        match['name'] = data['name']
      return match


class NestedMatch
  constructor: (item, type) ->
    @result = ''

    if type == 'tags'
      @result = item.join(', ')
    if type == 'phones'
      @result = item[0].number if item[0]
    if type == 'mails'
      @result = item[0].mail_address if item[0]
    if type == 'addresses'
      _address = item[0].street + ', ' + item[0].city_code + ' ' + item[0].city_name if item[0]
      @result = _address

    NestedMatch



angular.module('sc.filter')
.filter('searchFilter', ['$filter', '$translate', searchFilter])