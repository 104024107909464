class customerImport
  constructor: ->
    return ['cgBusy']

class customerImportConfig
  constructor: ($stateProvider, $provide) ->
    $stateProvider
    .state 'main.customer.import',
      url: '/import'
      abstract: true
      template: "<div ui-view=''></div>"

    .state 'main.customer.import.create',
      url: "/create"
      templateUrl: 'customer/import/create.tpl.html',
      controller: "customerImportCreateController",
      data:
        pageTitle: 'Customer View :: import'
        pageName: 'Customer import'

    .state 'main.customer.import.edit',
      url: '/edit/:crowdId'
      templateUrl: 'customer/import/edit.tpl.html',
      controller: 'customerImportEditController'
      data:
        pageTitle: ''
      resolve:{
        'picksData': (Picks) ->
          return Picks.picksPromise
      }


class customerImportCreate
  constructor: ($scope, $state, toastr, $translate, Upload) ->
  
    $scope.data = {
      tags: []
      file_name: ''
    }

    $scope.tags = []
    tag_list = []

    $scope.refreshTags = (word) ->
      if word
        $scope.tags = []
        for tag in tag_list
          if _.startsWith(tag.toLowerCase(), word.toLowerCase())
            $scope.tags.push(tag)
      return $scope.tags

    upload = (file, data) ->
      $scope.uploadPromise = Upload.upload({
        url: 'crowd/import',
        file: file,
        data: { 'name': _.trimEnd(data.file_name, '.csv'), file: file, tags: data.tags }
      })
      $scope.uploadPromise.then (resp) ->
        $state.go 'main.customer.import.edit', { 'crowdId': resp.data.crowd_id }
      , (resp) ->
        toastr.error("This upload can't be executed.", "Bad csv")

    $scope.submit = (file) ->
      if file
        toastr.info("Please be patient. This may take a while.", "Uploading...")
        upload(file, $scope.data)

class customerImportEdit
  constructor: ($scope, $state, $stateParams, toastr, $translate, Crowd, pagingService, $filter) ->
    $scope.data =
        name: 'Unnamed crowd'
        tags: []
        allIndividuals: []
        individuals: []
        errors: []

    $scope.tags = []
    tag_list = []

    $scope.refreshTags = (word) ->
      if word
        $scope.tags = []
        for tag in tag_list
          if _.startsWith(tag.toLowerCase(), word.toLowerCase())
            $scope.tags.push(tag)
      return $scope.tags

    $scope.pager = {}

    setPage = (page) ->
      if(page < 1)
        return
      $scope.pager = pagingService.GetPager($scope.data.allIndividuals.length, page)
      $scope.data.individuals = $scope.data.allIndividuals.slice($scope.pager.startIndex, $scope.pager.endIndex + 1)
      hideEditIndividuals()

    $scope.setPage = setPage

    $scope.migrated_status = ['cat-state', 'ignore', 'imported', 'identified', 'assimilated']

    $scope.showSelect = (status) ->
      return !_.includes($scope.migrated_status, status)

    $scope.individualToEdit = undefined
    $scope.showEdit = false

    $scope.createIndividual = ->
      $scope.individualToEdit =
        phones: [],
        mails: [],
        credentials: [],
        addresses: [{
          'address_type': 'home'
        }],
        key_values: []
      $scope.showEdit = true

    hideEditIndividuals = ->
      $scope.showEdit = false
      $scope.individualToEdit = undefined

    $scope.selectIndividual = (individual_id) ->
      Crowd.one($stateParams.crowdId).one('individual', individual_id).customGET()
        .then (response) ->
          $scope.individualToEdit = response.individual
          $scope.showEdit = true
          $scope.data.errors = []
        , (resp) ->
          toastr.error(resp.data.message, 'Error while getting individual.')


    $scope.deleteIndividual = (individual) ->
      Crowd.one($stateParams.crowdId).one('individual', individual.individual_id).customDELETE()
        .then (response) ->
          toastr.info("Individual has been executed", "Deleted")
          $scope.data.individuals = []
          filterIndividuals($scope.pager.currentPage)
        , (resp) ->
          toastr.error(resp.data.message, 'Error while deleting.')

    $scope.changeIndividual = (individual) ->
      if individual.individual_id
        Crowd.one($stateParams.crowdId).one('individual', individual.individual_id).customPUT(individual)
          .then (response) ->
            toastr.info("Individual has been changed", "Changed")
            hideEditIndividuals()
            $scope.data.errors = []
            filterIndividuals($scope.pager.currentPage)
          , (resp) ->
            $scope.data.errors = resp.data.errors
            toastr.error(resp.data.message, 'Error while changing.')
      else
        hasAddress = _.has(individual.addresses[0], 'street') || _.has(individual.addresses[0], 'city_code')
        if !hasAddress
          individual.addresses = []
        Crowd.one($stateParams.crowdId).one('individual').customPOST(individual)
          .then (response) ->
            toastr.info("Individual has been created", "Created")
            hideEditIndividuals()
            $scope.data.errors = []
            filterIndividuals(1)
          , (resp) ->
            $scope.data.errors = resp.data.errors
            toastr.error(resp.data.message, 'Error while creating.')

    if $stateParams.crowdId
      Crowd.one($stateParams.crowdId).get().then (response) ->
        $scope.data.name = response.crowd.name
        for tag in response.crowd.tags
          $scope.data.tags.push(tag)
        $scope.data.allIndividuals = response.crowd.individuals
        $scope.setPage(1)
      , (resp) ->
        toastr.error(resp.data.message, 'Error while getting crowd.')

    $scope.createCustomers = ->
      toastr.info("Please be patient you might grab a coffee but you should run.", "Importing individuals...")
      Crowd.one($stateParams.crowdId).post().then (response) ->
        $scope.data.allIndividuals = response.crowd.individuals
        $scope.setPage(1)
        toastr.success(
          response.created_customers + " new customers." + response.updated_customers + " updated customers."
        , "Customers created")
      , (resp) ->
        toastr.error(resp.data.message, "Create error")

    $scope.selectedDealId = undefined
    $scope.selectDeal = (deal) ->
      $scope.selectedDealId = deal._id

    $scope.executeAction = (action, collection_id) ->
      payload =
        collection_id: collection_id
        action: action
      Crowd.one($stateParams.crowdId).one('action').customPOST(payload).then (response) ->
        toastr.success('Action was successfull.', response.message)
      , (resp) ->
        toastr.error(resp.data.message, "Action error")

    $scope.changeCrowd = ->
      payload =
        name: $scope.data.name
        tags: $scope.data.tags

      Crowd.one($stateParams.crowdId).customPUT(payload).then (response) ->
        toastr.success('Crowd has been changed.', response.message)
      , (resp) ->
        toastr.error(resp.data.message, "Change error")

    $scope.deleteCrowd = ->
      Crowd.one($stateParams.crowdId).customDELETE().then () ->
        $state.go 'main.customer.import.create'
      , (resp) ->
        toastr.error(resp.data.message, "Delete error")

    $scope.countBadIndividuals = ->
      return _.filter($scope.data.allIndividuals, (individual) -> return individual.errors.length).length
    #Quick filter
    $scope.filter =
      badActive: false
      matchedCustomerActive: false
      unmatchedCustomerActive: false

    $scope.filterBadIndividuals = ->
      $scope.filter.badActive =! $scope.filter.badActive
      filterIndividuals(1, true)

    $scope.filterMatchedCustomers = ->
      $scope.filter.matchedCustomerActive =! $scope.filter.matchedCustomerActive
      if ($scope.filter.matchedCustomerActive and $scope.filter.unmatchedCustomerActive)
        $scope.filter.unmatchedCustomerActive = false
      filterIndividuals(1, true)

    $scope.filterUnmatchedCustomers = ->
      $scope.filter.unmatchedCustomerActive =! $scope.filter.unmatchedCustomerActive
      if ($scope.filter.matchedCustomerActive and $scope.filter.unmatchedCustomerActive)
        $scope.filter.matchedCustomerActive = false
      filterIndividuals(1, true)

    filterIndividuals = (currentPage, showToastr=false) ->
      Crowd.one($stateParams.crowdId).customGET(
        "filter", {
          'errors': $scope.filter.badActive,
          'matched_customers': $scope.filter.matchedCustomerActive,
          'unmatched_customers': $scope.filter.unmatchedCustomerActive
        }
      ).then (response) ->
        $scope.data.allIndividuals = response.crowd.individuals
        $scope.setPage(currentPage)
        if showToastr
          toastr.success("Filter complete.", "Individuals filtered")
      , (resp) ->
        toastr.error(resp.data.message, "Filter error")

    $scope.autoAssimilate = ->
      toastr.info("Starting assimilation.", "Let's go.")
      Crowd.one($stateParams.crowdId).one('auto_assimilate').customPUT(
      ).then (response) ->
        $scope.data.allIndividuals = response.crowd.individuals
        $scope.setPage(1)
        toastr.success("Assimilation complete.", "Resistence is futile")
      , (resp) ->
        toastr.error(resp.data.message, "Assimilation failed.")


class individualEdit
  constructor: () ->
    return {
    restrict: 'E'
    templateUrl: 'customer/import/edit_individual.tpl.html'
    scope:
      model: '='
      update: '&'
      errors: '='

    controller: ($scope, Customer, Control, Picks) ->
      $scope.titles = Customer.titles
      $scope.types =
        phone: Customer.phoneTypes
        mail: Customer.mailTypes
        address: Customer.addressTypes
        credential: Customer.credentialTypes
        sources: Customer.sources
        key_values_picks: Picks.getPicksByName('key_values')?.pick_values

      $scope.tags = []
      tag_list = []

      Control.customerTagService.one().get().then (response) ->
        tag_list = response.tags

      $scope.refreshTags = (word) ->
        if word
          $scope.tags = []
          for tag in tag_list
            if _.startsWith(tag.toLowerCase(), word.toLowerCase())
              $scope.tags.push(tag)
        return $scope.tags
    }

class importChoice
  constructor: () ->
    return {
    restrict: 'E'
    templateUrl: 'customer/import/import_choice.tpl.html'
    scope:
      model: '='
      update: '&'

    controller: ($scope, $filter) ->
      i18nAssimilate = $filter('translate')('assimilate')

      $scope.migration_status =
        value: $scope.model.migration_status
        id: $scope.model.assimilator_id

      $scope.getMigrationStatus = (individual) ->
        migration_status = [{
          'value': 'import',
          'label': 'import',
          'id': null
        }]
        
        for matched_customer in individual.matched_customer
          migration_status.push({
            label: i18nAssimilate + ' (' + matched_customer.number + ')',
            value: 'assimilate'
            id: matched_customer._id
          })
        return migration_status

      $scope.change = (model) ->
        model.migration_status = $scope.migration_status.value
        model.assimilator_id = $scope.migration_status.id
        $scope.update(model)
    }

# Google like paging
class Paging
  constructor: ->
    @GetPager = (totalItems, currentPage, pageSize) ->
      currentPage = currentPage || 1
      pageSize = pageSize || 100
      startPage = 0
      endPage = 0
      totalPages = Math.ceil(totalItems / pageSize)

      if (totalPages <= 10)
        startPage = 1
        endPage = totalPages
      else
        if (currentPage <= 6)
          startPage = 1
          endPage = 10
        else if (currentPage + 4 >= totalPages)
          startPage = totalPages - 9
          endPage = totalPages
        else
          startPage = currentPage - 5
          endPage = currentPage + 4

      startIndex = (currentPage - 1) * pageSize
      endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1)
      pages = _.range(startPage, endPage + 1)

      return {
        totalItems: totalItems,
        currentPage: currentPage
        pageSize: pageSize
        totalPages: totalPages,
        startPage: startPage,
        endPage: endPage,
        startIndex: startIndex,
        endIndex: endIndex,
        pages: pages
      }


angular.module('customer.import', new customerImport())
.config(['$stateProvider', '$provide', customerImportConfig])
.controller('customerImportCreateController', ['$scope', '$state', 'toastr', '$translate', 'Upload', customerImportCreate])
.controller('customerImportEditController', ['$scope', '$state', '$stateParams', 'toastr', '$translate', 'Crowd', 'pagingService', '$filter', customerImportEdit])
.directive('individualEdit', [individualEdit])
.directive('importChoice', [importChoice])
.service('pagingService', [Paging])