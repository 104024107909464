class scSkill
  constructor: ->
    return [
      'skill.skill'
      'skill.pointType'
    ]

class skillConfig
  constructor: ($stateProvider, $provide) ->

    $stateProvider
    .state 'main.skill',
      abstract: true,
      template: "<div ui-view=''></div>"
      url: '/skill'
      controller: 'skillController',
      data: {}


class skill
  constructor: ($state, $scope) ->
    $scope.$on '$stateChangeSuccess', (event, toState, toParams, fromState, fromParams) ->
      $scope.pageTitle = toState.data.pageTitle if angular.isDefined(toState.data.pageTitle)
      return


angular.module('sc.skill', new scSkill())
.config(['$stateProvider', '$provide', skillConfig])
.controller('skillController', ['$state', '$scope', skill])